
import {SnackBar }from './CommonActions'; 





export function CartAddItem(data){
    return function(dispatch) {
        dispatch(CartAddItemAction(data));
        dispatch(storeTourDetails(data));
        dispatch(SnackBar(true,'Item added to your cart','info'));
    }

}

export function CartUpdateItem(data,itemIndex){
    return function(dispatch) {
        dispatch(CartUpdateItemAction(data,itemIndex));
        dispatch(storeTourDetails(data,itemIndex));
        dispatch(SnackBar(true,'Item updated','info'));
    }

}

export function storeTourDetails(list) {
    console.log(list)
    return {
        type: 'STORE_TOUR_DETAILS',
        list: list
    }
}

export function CartAddItemAction(data){
    data['booking_object'] = CreateBookingObject(data);
    return {
        type: 'ADD_ITEM',
        item:data,
    }
}

export function CartUpdateItemAction(data,itemIndex){
    data['booking_object'] = CreateBookingObject(data);
    return {
        type: 'UPDATE_CART_ITEM',
        item:data,
        index:itemIndex
    }
}

export function BookNow(status) {
    return {
        type: 'BOOK_NOW',
        bookNow:status
    }
}



export function storeCartItems(results) {
    return {
        type: 'STORE_CART_ITEMS',
        list:results.data.items,
        total:results.data.total,
        totalFormatted:results.data.total_formatted,

    }
}


export function CartRemoveItem(ItemIndex){

    return {
        type: 'REMOVE_ITEM',
        index:ItemIndex
    }

}


export function CartRemoveAllItem(){

    return {
        type: 'REMOVE_ALL_ITEM'
    }

}


export function GetCartTotal(items){
       
    return items.reduce((total,item)=>{
        console.log(item);
        return parseFloat(item.amount) + total;
    },0);
}

export function CreateBookingObject(cartItem){

        var numTravelers =  parseInt(cartItem.adults)+ parseInt(cartItem.children)+ parseInt(cartItem.infants);
        var travelers = [];

        for(var x=0; x < numTravelers; x++){
            travelers.push({
                title:"Mr",
                firstname:"",
                lastname:"",
                email:"",
                phone:"",
                lead_traveller: x === 0 ? 1:0
            })
        }



        return {
                    start_date  : cartItem.from_date,
                    activity_id : cartItem.activity.id,
                    num_adults : cartItem.adults,
                    num_children : cartItem.children,
                    num_infants : cartItem.infants,
                    remarks : null ,
                    booking_amount : cartItem.amount,
                    pickup_location : 'hotel',
                    hotel_name: "",
                    hotel_address : "",
                    flight_no : "",
                    flight_time : "" ,
                    contact_firstname : "" ,
                    contact_lastname : "",
                    contact_email : "",
                    contact_phone : "",
                    location_id:cartItem.location_id,
                    pickup_location_id:cartItem.pickup_location_id,
                    pickup_time:cartItem.pickuptime,
                    traveller : travelers,
               }
}
