export default function reducer(state={
    cms:[],
    activecms:{
        title:"",
        id:0,
        content:"",
        discount:null,
        permalink:null,
        full_image:null,
        image:null,
        seo_title:"",
        seo_description:"",
        seo_keywords:"",
        image_thumb:null
    }

},action){

    switch(action.type){
        case "STORE_CMS":{
            return{...state,cms:action.cms}
        }

        case "STORE_ACTIVE_CMS":{
            console.log(action.cms)
            return{...state,activecms:{
               ...state.activecms,
                id:action.cms.id,
                title:action.cms.title,
                content:action.cms.content,
                image_thumb:action.cms.image_thumb,
                permalink:action.cms.permalink,
                full_image:action.cms.full_image,
                image:action.cms.image,
                updated:true,
                seo_title:action.cms.seo_title,
                seo_description:action.cms.seo_description,
                seo_keywords:action.cms.seo_keywords

            }

    }
        }
    }

    return state;
}
