import { applyMiddleware, createStore, combineReducers } from 'redux'; 

import logger from 'redux-logger'
import thunk from 'redux-thunk';
import promise from "redux-promise";


import { loadAuthState, saveAuthState } from './Modules/AuthSession';
import { cartSaveState, loadCartState } from './Modules/CartSession';
import { loadSearchState, searchSaveState } from './Modules/SearchSession';

import reducers from "./Reducers"

import throttle from 'lodash/throttle';


const middleware = applyMiddleware(promise, thunk, logger)

const store = createStore(reducers, { Auth: loadAuthState(),Cart: loadCartState(),Search: loadSearchState() }, middleware);

store.subscribe(throttle(() => {
    saveAuthState({
        Auth: store.getState().Auth
    });

    cartSaveState({
        Cart: store.getState().Cart
    });

    
    searchSaveState({
        Search: store.getState().Search
    });

}, 1000));

export default store;
