import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Headerinner from '../Pages/common/HeaderInner';
import Footer from '../Pages/common/Footer';
import { MakeBooking,ValidateBookingForm } from '../Actions/WebsiteActions';
import { BookNow,GetCartTotal} from '../Actions/CartActions';
import {Helmet} from "react-helmet";
import CheckoutItem from '../Pages/common/CheckoutItem';
import {Redirect} from 'react-router-dom'
import { SnackBar } from '../Actions/CommonActions';

const currencyFormat = (num) => {  
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const getFormattedPrice = (price)=>{
  let splittedPrice = price.toString().split('.');

  if(splittedPrice.length === 1){
    return <div>{splittedPrice[0]}.00</div>;
  }

  return <div>{splittedPrice[0]}<sup>.{splittedPrice[1]}</sup></div>
}

const mapStateToProps = (state) => {
    return {
      CartItems: state.Cart.items,
      CartTotal:GetCartTotal(state.Cart.items),
      CartTotalFormatted:state.Cart.totalFormatted,
      returnHome:state.Cart.returnHome
    }
}

const mapDispatchToProps = dispatch => ({
  MakeBooking:(data)=>dispatch(MakeBooking(data)),
  BookNow:(status)=>dispatch(BookNow(status)),
  ValidateBookingForm:(data,agreement)=>dispatch(ValidateBookingForm(data,agreement)),
  SnackBar : (show,message,varient) => dispatch(SnackBar(show,message,varient))

});


class Checkout extends Component {

    owlRef = React.createRef();
    featuredItemsRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
          CartItems: this.props.CartItems,
          firstname: null,
          lastname : null,
          email: null,
          phone : null,
          agreement:false,
          cartLoaded:false,

          conFn:null,
          conLastname:null,
          conEmail:null,
          conPhone:null,

          traFirstName:null,
          traLastName:null,
          traEmail:null,
          traPhone:null,
          traPickupLoc:null,

          errorAgreement:null,
        }

    this.props.BookNow(false);

    }


    handleChange = (event) => {
        this.setState({
            value: event.target.value
        })
    }

    makeBooking = () =>{

        var cartItem = this.state.CartItems;
        for(var x = 0; x < cartItem.length; x++){

          const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

           //Contact validation
          if(cartItem[x].booking_object.contact_firstname === ""){
            this.setState({
              conFn:"This field is required",
            })
          }
          else{
            this.setState({
              conFn:null,
            })
          }
           if(cartItem[x].booking_object.contact_lastname === ""){
            this.setState({
              conLastname:"This field is required"
            })
          }
          else{
            this.setState({
              conLastname:null,
            })
          }
           if(cartItem[x].booking_object.contact_email === ""){
            this.setState({
              conEmail:"This field is required"
            })
          }
          else if(reg.test(cartItem[x].booking_object.contact_email) !== true){
            this.setState({
              conEmail:"Email not valid"
            })
          }
          else{
            this.setState({
              conEmail:null,
            })
          }
           if(cartItem[x].booking_object.contact_phone === ""){
            this.setState({
              conPhone:"This field is required"
            })
          }
          else if(isNaN(cartItem[x].booking_object.contact_phone)){
            this.setState({
              conPhone:"Phone number not valid "
            })
          }
          else{
            this.setState({
              conPhone:null,
            })
          }

          //Traveller validation
          if(cartItem[x].booking_object.traveller[0].firstname === ""){
            this.setState({
              traFirstName:"This field is required",
            })
          }
          else{
            this.setState({
              traFirstName:null,
            })
          }
           if(cartItem[x].booking_object.traveller[0].lastname === ""){
            this.setState({
              traLastName:"This field is required"
            })
          }
          else{
            this.setState({
              traLastName:null,
            })
          }
           if(cartItem[x].booking_object.traveller[0].email === ""){
            this.setState({
              traEmail:"This field is required"
            })
          }
          else if(reg.test(cartItem[x].booking_object.traveller[0].email) !== true){
            this.setState({
              traEmail:"Email not valid"
            })
          }
          else{
            this.setState({
              traEmail:null,
            })
          }
           if(cartItem[x].booking_object.traveller[0].phone === ""){
            this.setState({
              traPhone:"This field is required"
            })
          }
          else if(isNaN(cartItem[x].booking_object.traveller[0].phone)){
            this.setState({
              traPhone:"Phone number not valid "
            })
          }
          else{
            this.setState({
              traPhone:null,
            })
          }
          // if(cartItem[x].booking_object.pickup_location === 'hotel'){
          //   if(cartItem[x].booking_object.hotel_name.length === 0){
          //     this.setState({
          //       traPickupLoc:"Pickup Location name can not be empty"
          //     })
          //   }
          //   else{
          //     this.setState({
          //       traPickupLoc:null,
          //     })
          //   }
          //   if(cartItem[x].booking_object.hotel_address.length === 0){
          //     this.setState({
          //       traPickupLoc:"Pickup Location address can not be empty"
          //     })
          //   }
          //   else{
          //     this.setState({
          //       traPickupLoc:null,
          //     })
          //   }
          // }
          if(cartItem[x].booking_object.pickup_location === "" ){
            this.setState({
              traPickupLoc:"Pickup location can not be empty",
            })
          }
          else{
            this.setState({
              traPickupLoc:null,
            })
          }
          console.log(cartItem[x].booking_object.contact_firstname === "" && cartItem[x].booking_object.contact_lastname === "" && cartItem[x].booking_object.contact_email === "" && cartItem[x].booking_object.contact_phone === "" && cartItem[x].booking_object.traveller[0].firstname === "" && cartItem[x].booking_object.traveller[0].lastName === "" && cartItem[x].booking_object.traveller[0].email === "" && cartItem[x].booking_object.traveller[0].phone === ""  && cartItem[x].booking_object.pickup_location === "","asffgwegwrwrg")
          if(cartItem[x].booking_object.contact_firstname === "" || cartItem[x].booking_object.contact_lastname === "" || cartItem[x].booking_object.contact_email === "" || cartItem[x].booking_object.contact_phone === "" || cartItem[x].booking_object.traveller[0].firstname === "" || cartItem[x].booking_object.traveller[0].lastName === "" || cartItem[x].booking_object.traveller[0].email === "" || cartItem[x].booking_object.traveller[0].phone === ""  || cartItem[x].booking_object.pickup_location === "")
            {return false}
          if(this.state.agreement === false){
            this.setState({
              errorAgreement:null,
            })
            this.props.SnackBar(true,"You need to agree to terms & conditions","error")
          }
          else{
            this.setState({
              errorAgreement:null,
            })
          }

          if(cartItem[x].booking_object.contact_firstname !== "" && cartItem[x].booking_object.contact_lastname !== "" && cartItem[x].booking_object.contact_email !== "" && cartItem[x].booking_object.contact_phone !== "" && cartItem[x].booking_object.traveller[0].firstname !== "" && cartItem[x].booking_object.traveller[0].lastName !== "" && cartItem[x].booking_object.traveller[0].email !== "" && cartItem[x].booking_object.traveller[0].phone !== "" && this.state.agreement !== false && cartItem[x].booking_object.pickup_location !== ""){
            this.props.ValidateBookingForm(cartItem,this.state.agreement);
          }  

          //end
        }

	}

	contactChange = (event,field) =>{
		for(var $x=0; $x < this.state.CartItems.length; $x++){
			this.handleContactChange(event,field,$x);
		}
  }
	handleContactChange = (event,field,ItemIndex)=>{
   
		this.setState({
			...this.state,
			CartItems: Object.values({
				...this.state.CartItems,
				[ItemIndex]:{
					...this.state.CartItems[ItemIndex],
					booking_object:{
						...this.state.CartItems[ItemIndex].booking_object,
							[field]:event.target.value
						}
				}


			})

		});
	}


	handlePickupChange = (event,field,ItemIndex)=>{
		this.setState({
			...this.state,
			CartItems: Object.values({
				...this.state.CartItems,
				[ItemIndex]:{
					...this.state.CartItems[ItemIndex],
					booking_object:{
						...this.state.CartItems[ItemIndex].booking_object,
							[field]:event.target.value
						}
				}


			})

		});
	}

	handleCopyInfo = (e) => {

		if(e.target.checked){

			var firstName = this.state.CartItems[0].booking_object.traveller[0].firstname;
			var lastName  = this.state.CartItems[0].booking_object.traveller[0].lastname;
			var email = this.state.CartItems[0].booking_object.traveller[0].email;
			var phone = this.state.CartItems[0].booking_object.traveller[0].phone;

			this.setState({
				firstname: firstName,
				lastname : lastName,
				email: email,
				phone : phone
			},()=>{
				for(var $x=0; $x < this.state.CartItems.length; $x++){
					this.handleContactChangeGrouped({
						firstname: firstName,
						lastname : lastName,
						email: email,
						phone : phone
					},$x);
				}
			});
    }
    else{
       this.setState({
        firstname: this.state.contact_firstname,
				lastname : this.state.contact_lastname,
				email: this.state.contact_email,
				phone : this.state.contact_phone
       })
    }
	}


	handleContactChangeGrouped = (data,ItemIndex)=>{
		this.setState({
			...this.state,
			CartItems: Object.values({
				...this.state.CartItems,
				[ItemIndex]:{
					...this.state.CartItems[ItemIndex],
					booking_object:{
						...this.state.CartItems[ItemIndex].booking_object,
							contact_firstname:data.firstname,
							contact_lastname: data.lastname,
							contact_email:data.email,
							contact_phone:data.phone
						}
				}


			})

		});
  }
  
  

	handleTravelerChange = (event,field,ItemIndex,TravelerIndex) =>{


		this.setState({
			...this.state,
			CartItems: Object.values({
				...this.state.CartItems,
				[ItemIndex]:{
					...this.state.CartItems[ItemIndex],
					booking_object:{
						...this.state.CartItems[ItemIndex].booking_object,
						traveller:Object.values({
							...this.state.CartItems[ItemIndex].booking_object.traveller,
							[TravelerIndex]:{
								...this.state.CartItems[ItemIndex].booking_object.traveller[TravelerIndex],
								[field]:event.target.value
							}

						})
						}
				}


			})

		});
	}

	acceptAgreement = (e) =>{
		this.setState({
			agreement : e.target.checked
		});
	}

  formatDate = (date) => {
  var monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];

  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();

  return day + ' ' + monthNames[monthIndex] + ' ' + year;
}

    render() {

        const { CartTotal } = this.props;


        return <Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Checkout</title>
          {/* <meta name="description" content="description" />
          <meta property="og:title" content="og:title" />
          <meta property="og:type" content="article" />
          <meta property="og:url" content="og:url" />
          <meta property="og:image" content="og:image" /> */}
        </Helmet>
           <Headerinner></Headerinner>

           <div className="contentwrap">
    <div className="title-wrap crt">
      <div className="container">
        <div className="row">
          <div className="col l3 m12 s12">
            <div className="breadscrumb-wrap">
               <div className="nav-wrapper">
                <div className="row">
                <div className="col s12">
                    <a  style={{backgroundColor:"white"}} href="/" className="breadcrumb">Home</a>
                    <a  style={{backgroundColor:"white"}} href="Checkout" className="breadcrumb">Checkout</a>
                </div>

                </div>
                </div>
            </div>
            <div className="m-title"><h1>Checkout</h1></div>
          </div>
          <div className="col l9 m12 s12">


          </div>
        </div>
      </div>
    </div>
    <div className="cart-checkout-wrap">
      <div className="container">
        <div className="row">
          <div className="col l8 m12 s12">
            { this.state.CartItems.map((item,index)=>{
										return <CheckoutItem
																onTravelerChange={this.handleTravelerChange}
																onPickupChnage={this.handlePickupChange}
                                cartItemIndex={index}  data={item}
                                errorFn = {this.state.traFirstName}
                                errorLn = {this.state.traLastName}
                                errorEmail = {this.state.traEmail}
                                errorphone = {this.state.traPhone}
                                errorPickup = {this.state.traPickupLoc} />
								})}



            <div className="contact-info-wrap">
              <div className="c-info-title">
                <h4>Contact Information</h4>
                <label>
                  <input type="checkbox" className="filled-in"  onChange={this.handleCopyInfo} /> 
                  <span>Same as above</span>
                </label>
              </div>
              <div className="c-info-form">




                 <div className="row">
                    <div className="input-field col m6 s12">
                    <p style={{paddingTop:15}}>First Name</p>
                      <input id="first_name" type="text" className="validate" value={this.state.firstname}  onChange={(e)=>{this.contactChange(e,'contact_firstname')}}/>
                      {this.state.conFn !== null ? <span style={{ color:"red"}}> {this.state.conFn} </span> : ''}
                    </div>
                    <div className="input-field col m6 s12">
                    <p style={{paddingTop:15}}>Last Name</p>
                      <input id="last_name" type="text" className="validate" value={this.state.lastname} onChange={(e)=>{this.contactChange(e,'contact_lastname')}}/>
                      {this.state.conLastname !== null ? <span style={{ color:"red"}}> {this.state.conLastname} </span> : ''}
                    </div>
                  </div>
                  <div className="row">
                    <div className="input-field col m6 s12">
                    <p style={{paddingTop:15}}>Contact Number</p>
                      <input id="contact_number" type="text" className="validate" value={this.state.phone}  onChange={(e)=>{this.contactChange(e,'contact_phone')}}/>
                      {this.state.conPhone !== null ? <span style={{ color:"red"}}> {this.state.conPhone} </span> : ''}
                    </div>
                    <div className="input-field col m6 s12">
                    <p style={{paddingTop:15}}>Email Address</p>
                      <input id="email" type="email" className="validate" value={this.state.email}  onChange={(e)=>{this.contactChange(e,'contact_email')}}/>
                      {this.state.conEmail !== null ? <span style={{ color:"red"}}> {this.state.conEmail} </span> : ''}
                    </div>
                  </div>
              </div>
              <div className="terms">
                  <label>
                    <input type="checkbox" 	onChange={this.acceptAgreement}	 />
                    <span>I agree the <a href="./Terms" target="_blank">Terms & Conditions</a></span><br />
                    {this.state.errorAgreement !== null ? <span style={{ color:"red"}}> {this.state.errorAgreement} </span> : ''}
                  </label>
              </div>




            </div>



          </div>
          <div className="col l4 m12 s12 chk-booking-col">
            <div className="cart-price-info-wrap">
              <div className="cart-info-section">
                <div className="cart-total-wrap">
                  <h4>Cart Total </h4>
                  <div style={{display:"flex"}}  className="ct-price">$ {this.props.CartTotal!==null?getFormattedPrice(currencyFormat(this.props.CartTotal)):null }  </div>

                </div>

                  {/* {this.props.CartTotal!==null?getFormattedPrice(this.props.CartTotal):''} */}
                <div className="total-price">
                  <h5>Total price</h5>
                  <h6 style={{display:"flex"}}>${this.props.CartTotal!==null?getFormattedPrice(currencyFormat(this.props.CartTotal)):null }</h6>
                </div>
                <div className="bknowwrap"> 
                  {this.state.CartItems.length!==0? <a className="waves-effect waves-light btn pay bknw" onClick={()=>{this.makeBooking()}}>Book now</a>:null} 
                </div>
                <div className="clearfix"></div>
                { this.props.CartItems.length > 1 ? (
                  <div className="tour-routewrap">
                    <div className="t-start">
                      <h5>Starts</h5>
                      <h6>{this.props.CartItems[0].activity.name}</h6>
                       <span className="start-cal">{this.formatDate(new Date(this.props.CartItems[0].from_date))}</span>
                       <span className="tour-location">{this.props.CartItems[0].activity.activityObject.location !== null ? this.props.CartItems[0].activity.activityObject.location.name : null }</span>
                    </div>

                    <div className="t-start">
                      <h5>ends</h5>
                      <h6>{this.props.CartItems[this.props.CartItems.length-1].activity.name}</h6>
                       <span className="start-cal">{this.formatDate(new Date(this.props.CartItems[this.props.CartItems.length-1].from_date))}</span>
                       <span className="tour-location">{this.props.CartItems[this.props.CartItems.length-1].activity.activityObject.location !== null ? this.props.CartItems[this.props.CartItems.length-1].activity.activityObject.location.name : null }</span>
                    </div>
                  </div>
                ):null}
              </div>

               <div className="cart-info-section">

                <div className="cart-infos">
                  <h5>Lowest price Guarantee</h5>
                  <p>find it cheaper? We 'll refund the difference</p>
                </div>

                <div className="cart-infos">
                  <h5>24/7 Global Support</h5>
                  <p>Get the answers you need, when you need them</p>
                </div>
                <div className="question-wrap">
                    <h3>For Questions call us</h3>
                    <a href="tel:+94 112 579 679">+94 112 579 679</a>
                </div>

              </div>

            </div>

          </div>
        </div>
      </div>


    </div>


  </div>

            <Footer/>
            
            {this.props.returnHome&&this.props.CartItems.length===0 ? <Redirect to="/confirmed"></Redirect>:null}
        </Fragment>
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Checkout);
