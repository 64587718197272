import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import StarRatingComponent from 'react-star-rating-component';

const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = dispatch => ({

});

const getFormattedPrice = (price)=>{
    let splittedPrice = price.toString().split('.');

    return <Fragment>{splittedPrice[0]}<sup>.{splittedPrice[1]}</sup></Fragment>
}

class GridCard extends Component {


  getDuration(measure){
    switch(measure){
      case 1 :{ return "Days" }
      case 2 :{ return "Hours" } 
      case 3 :{ return "Minutes" }
      default:
          break
    }
  }

    render() {
            console.log("sajith recomend",this.props.data)
        const { data } = this.props;

        return <div className="item">
            <div className="card" style={{cursor:'pointer'}} onClick={()=>{ window.location.href="/tour/show/"+data.id }}>
                <div className="card-image">
                    {data.featured === 1 ? (<span className="t-featured"><i className="sprite sprite-featured-rec"></i></span>):null}
                    {data.deal_price!==null&&data.price !== null?<span className="s-offer">Special offer</span>:null} 
               
                    <a style={{cursor:'pointer'}} onClick={()=>window.location.href="./tour/show/"+this.props.data.id}><img src={data.thumb_image}  /></a>
                    <span className="more-info">{data.categories[0].category_name} { data.categories.length > 1 ? (<em>+{data.categories.length-1} more</em>) : null }</span>
                </div>
                <div className="card-content">
                    <div className="tour-title">
                        <h4><a style={{cursor:'pointer'}} onClick={()=>{ window.location.href="/tour/show/"+data.id }}>{data.title.substr(0, 53)}{data.title.length > 53 ? '...':''}</a></h4>
                    </div>
                    <div className="tour-sec1">
                        <span className="tour-location">{ data.location !== null ? data.location.name:null}</span>
                    </div>
                    <div className="tour-info">
                        <div className="tour-duration"><i className="sprite sprite-tour-duration-icon"></i>{data.duration_value} {this.getDuration(data.duration)}</div>
                        { data.free_cancellation === 1 ? (<div className="tour-option"><i className="sprite sprite-tour-checked"></i> Free Cancellation</div>):null }
                    </div>
                    <div className={`tour-pricewrap ${data.deal_price !== null ? "hasdiscount":""}`}>
                        <div className="ratewrap">
                          <StarRatingComponent
                                      name="rate2"
                                      editing={false}
                                      renderStarIcon={() => <span></span>}
                                      starCount={5}
                                      value={this.props.data.average_rating}
                                      renderStarIcon={() => <i style={{ fontSize: 21, fontStyle: 'normal',color:'#f7cf2e' }} > {this.props.data.average_rating === null ? ('☆'):'★' } </i>}
                                    />
                        </div>
                        <div className="ratestart">from </div>
                        <div className="tour-prices"> 
                        {data.deal_price!==null&&data.price !== null?<span className="oldsprice"> $ {data.price.selling_price}  </span>:null} 
                        <span className="newsprice">{data.deal_price !== null?<span>$ { getFormattedPrice(data.deal_price)} </span>:<span>$ {data.price !== null ? getFormattedPrice(data.price.selling_price):''} </span>}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(GridCard);
