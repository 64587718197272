import React from 'react';



export default function (props) {
    return <div className="s-itmes my-slide">
        <a href={"./SearchResult?location="+props.data.id}>
            <img src={props.data.full_image} width="100%" alt="" className=""></img>
            <div className="desti-text">
                <h5>{props.data.name}</h5>
                <p>{props.data.num_activities} Tours & Activities</p>
            </div>
        </a>
    </div>;
}
