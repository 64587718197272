import React, {  Component, Fragment } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import {fetchCategories}from '../../Actions/CategoryAction';
import {fetchLocations}from '../../Actions/LocationAction';
import {TopSellingActivities} from '../../Actions/ActivityActions';

const queryString = require('query-string');

const mapStateToProps = (state) => {
    return {

        Categories:state.Categories.categories,
        Locations:state.Locations.locations,
        Activity:state.Activities.result,

    }
}

const mapDispatchToProps = dispatch => ({
    fetchCategories:()=>dispatch(fetchCategories()),
    TopSellingActivities:()=>dispatch(TopSellingActivities()),
    fetchLocations:()=>dispatch(fetchLocations())
});




const tourTypes = [
                    {id:'1',name:'Day Tours'},
                    {id:'2',name:'Multi Day Tours'},
                    {id:'3',name:'Overnight Experiences'},
                    {id:'4',name:'Things to do'},
                    {id:'5',name:'Transfers'},
                  ];


class Searchbar extends Component {

    owlRef = React.createRef();
    featuredItemsRef = React.createRef();

    constructor(props) {
        super(props);

        var parsed = queryString.parse(this.props.location.search);


        this.state = {
            value: 0,
            locationOpen:false,
            categoryOpen:false,
            otherOpen:false,
      
            textValue: parsed.key !== "" && parsed.key !== undefined ? parsed.key : null,
            selecetedCategories:parsed.category !== "" && parsed.category !== undefined ? parsed.category.split(",") : "",
            selecetedLocations:parsed.location !== "" && parsed.location !== undefined ? parsed.location.split(",") : "",



        }

        this.props.fetchCategories();
        this.props.fetchLocations();
        this.props.TopSellingActivities();

    }
    sliderRef;

    updateSearch = () =>{

      var query = "";

      if(this.state.textValue !== null){
        query ="key="+this.state.textValue;
      }

       if(this.state.tour_type !== null){
        query = query !== "" ? query+"&":"";
        query = query+"type="+this.state.tour_type;
      }

      if(this.state.duration_min !== null){
        query = query !== "" ? query+"&":"";
        query = query+"duration_min="+ this.state.duration_min;
      }

      if(this.state.duration_max !== null){
        query = query !== "" ? query+"&":"";
        query = query+"duration_max="+ this.state.duration_max;
      }

      if(this.state.price_min !== null){
        query = query !== "" ? query+"&":"";
        query = query+"price_min="+ this.state.price_min;
      }

      if(this.state.price_max !== null){
        query = query !== "" ? query+"&":"";
        query = query+"price_max="+ this.state.price_max;
      }

       if(this.state.selecetedCategories !== null && this.state.selecetedCategories.length > 0){

         query = query !== "" ? query+"&":"";
         query = query+"category="+ this.state.selecetedCategories.join();
       }

 
      if(this.state.location!== false){
        query = query !== "" ? query+"&":"";
        query = query+"location="+this.state.location;
      }
    

       }


    setSliderRef = ( ref) =>{
        this.sliderRef = ref;
      }



  handleLocations = (event) =>{

    this.setState({
  ...this.state,
  selecetedLocations:[
    ...this.state.selecetedLocations,
    event.target.value
  ],
  view:'list'
  },()=>{
  this.updateSearch();

  });
  }

  handleChange = (event,field) =>{

  this.setState({[field]:event.target.value,view:'list'},()=>{
  this.updateSearch();

  });
  }

  handleDealChange = (event) =>{

  this.setState({deals:event.target.checked,view:'list'},()=>{
  this.updateSearch();

  });
  }


    render() {
        return (<Fragment>

<div className="tour-side-bar" >
<div className="side-bar-box">
                <div className="sb-box-title">
                  <h4>Categories</h4><br/>
                  <span><a style={{cursor:'pointer'}} onClick={()=>this.props.clearCategories()} className="celar-all">clear all</a></span>
                </div>

                <div className="sb-option-wrap">
                {this.props.Categories !== undefined && this.props.Categories.length > 0 ? this.props.Categories.map((data, index) => {
                 return  <div className="sb-checks">
                    <label>
                      <input type="checkbox" checked={this.props.searchParams.selecetedCategories.includes(data.id.toString())} onChange={this.props.handleCategory} value={data.id}    />
                     <span>{data.name}</span>
                    </label>
                  </div>
                    }):null}

                </div>
              </div>

              <div className="side-bar-box">
                <div className="sb-box-title">
                  <h4>Types</h4>
                  <a style={{cursor:'pointer'}} onClick={()=>this.props.clearTourTypes()} className="celar-all">clear all</a>
                </div>
                <div className="sb-option-wrap">
                  {tourTypes !== undefined && tourTypes.length > 0 ? tourTypes.map((data, index) => {
                   return  <div className="sb-checks">
                      <label>
                        <input type="checkbox" checked={this.props.searchParams.selecetedTourTypes.includes(data.id)} onChange={this.props.handleTourType} value={data.id}    />
                       <span>{data.name}</span>
                      </label>
                    </div>
                      }):null}
                </div>
              </div>
              <div className="side-bar-box">
                <div className="sb-box-title">
                  <h4>Top Destinations</h4>
                  <a style={{cursor:'pointer'}} onClick={()=>this.props.clearLocations()} className="celar-all">clear all</a>
                </div>

                <div className="sb-option-wrap">
                {this.props.Locations !== undefined && this.props.Locations.length > 0 ? this.props.Locations.map((data, index) => {
                 return (<div className="sb-checks">
                 <label>
                 <input type="checkbox" checked={this.props.searchParams.selecetedLocations.includes(data.id.toString())} onChange={this.props.handleLocations}  value={data.id}   />
                   <span>{data.name} <h6>({data.num_activities} Tours & Activities)</h6> </span>
                 </label>
               </div>)
                  }):null}

                </div>
              </div>

              
              </div>


        </Fragment>);
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Searchbar);
