import { API_URL} from './config';
import {endLoading, SnackBar,handleHTTPError} from './CommonActions';
import store from '../Store';

   
export function SearchActivities(keyword,location,tourType,durationMin,durationMax,category,priceMIn,priceMax,deals,featured) {
    console.log(deals);
    return function(dispatch) {
        dispatch(SnackBar(true,'Updating...','info'));
       
        fetch(API_URL+'search',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',              
            },
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                console.log(json.data)       
                  dispatch(storeSearchActivities(json.data.results));                                                     
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }   

}
   


export function storeSearchActivities(list) {
    console.log(list)
    return {
        type: 'STORE_SEARCH_ACTIVITIES',
        list: list
    }
}


export function storeSearchKey(Key) {
    return {
        type: 'STORE_SEARCH_KEY',
        Key: Key
    }
}


export function storeSearchKeyLock(Key) {
    console.log("LockSajith")
    return {
        type: 'STORE_SEARCH_KEY_LOCK',
        Key: Key
    }
}