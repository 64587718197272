import React, {  Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { AddTailorMade ,stopStateEmpty} from '../Actions/WebsiteActions';
import Paper from '@material-ui/core/Paper'
import Headerinner from '../Pages/common/HeaderInner';
import Footer from '../Pages/common/Footer';
import Datepickk from 'datepickk';
import {Helmet} from "react-helmet";
import Moment from 'react-moment';
import 'moment-timezone';
import {startLoading,endLoading,SnackBar } from '../Actions/CommonActions';
import {Redirect} from 'react-router-dom'
const mapStateToProps = (state) => {
    return {
      stateNull:state.Website.stateNull
    }
}

const countryList = [
	"Afghanistan",
	"Albania",
	"Algeria",
	"American Samoa",
	"Andorra",
	"Angola",
	"Anguilla",
	"Antarctica",
	"Antigua and Barbuda",
	"Argentina",
	"Armenia",
	"Aruba",
	"Australia",
	"Austria",
	"Azerbaijan",
	"Bahamas (the)",
	"Bahrain",
	"Bangladesh",
	"Barbados",
	"Belarus",
	"Belgium",
	"Belize",
	"Benin",
	"Bermuda",
	"Bhutan",
	"Bolivia (Plurinational State of)",
	"Bonaire, Sint Eustatius and Saba",
	"Bosnia and Herzegovina",
	"Botswana",
	"Bouvet Island",
	"Brazil",
	"British Indian Ocean Territory (the)",
	"Brunei Darussalam",
	"Bulgaria",
	"Burkina Faso",
	"Burundi",
	"Cabo Verde",
	"Cambodia",
	"Cameroon",
	"Canada",
	"Cayman Islands (the)",
	"Central African Republic (the)",
	"Chad",
	"Chile",
	"China",
	"Christmas Island",
	"Cocos (Keeling) Islands (the)",
	"Colombia",
	"Comoros (the)",
	"Congo (the Democratic Republic of the)",
	"Congo (the)",
	"Cook Islands (the)",
	"Costa Rica",
	"Croatia",
	"Cuba",
	"Curaçao",
	"Cyprus",
	"Czechia",
	"Côte d'Ivoire",
	"Denmark",
	"Djibouti",
	"Dominica",
	"Dominican Republic (the)",
	"Ecuador",
	"Egypt",
	"El Salvador",
	"Equatorial Guinea",
	"Eritrea",
	"Estonia",
	"Eswatini",
	"Ethiopia",
	"Falkland Islands (the) [Malvinas]",
	"Faroe Islands (the)",
	"Fiji",
	"Finland",
	"France",
	"French Guiana",
	"French Polynesia",
	"French Southern Territories (the)",
	"Gabon",
	"Gambia (the)",
	"Georgia",
	"Germany",
	"Ghana",
	"Gibraltar",
	"Greece",
	"Greenland",
	"Grenada",
	"Guadeloupe",
	"Guam",
	"Guatemala",
	"Guernsey",
	"Guinea",
	"Guinea-Bissau",
	"Guyana",
	"Haiti",
	"Heard Island and McDonald Islands",
	"Holy See (the)",
	"Honduras",
	"Hong Kong",
	"Hungary",
	"Iceland",
	"India",
	"Indonesia",
	"Iran (Islamic Republic of)",
	"Iraq",
	"Ireland",
	"Isle of Man",
	"Israel",
	"Italy",
	"Jamaica",
	"Japan",
	"Jersey",
	"Jordan",
	"Kazakhstan",
	"Kenya",
	"Kiribati",
	"Korea (the Democratic People's Republic of)",
	"Korea (the Republic of)",
	"Kuwait",
	"Kyrgyzstan",
	"Lao People's Democratic Republic (the)",
	"Latvia",
	"Lebanon",
	"Lesotho",
	"Liberia",
	"Libya",
	"Liechtenstein",
	"Lithuania",
	"Luxembourg",
	"Macao",
	"Madagascar",
	"Malawi",
	"Malaysia",
	"Maldives",
	"Mali",
	"Malta",
	"Marshall Islands (the)",
	"Martinique",
	"Mauritania",
	"Mauritius",
	"Mayotte",
	"Mexico",
	"Micronesia (Federated States of)",
	"Moldova (the Republic of)",
	"Monaco",
	"Mongolia",
	"Montenegro",
	"Montserrat",
	"Morocco",
	"Mozambique",
	"Myanmar",
	"Namibia",
	"Nauru",
	"Nepal",
	"Netherlands (the)",
	"New Caledonia",
	"New Zealand",
	"Nicaragua",
	"Niger (the)",
	"Nigeria",
	"Niue",
	"Norfolk Island",
	"Northern Mariana Islands (the)",
	"Norway",
	"Oman",
	"Pakistan",
	"Palau",
	"Palestine, State of",
	"Panama",
	"Papua New Guinea",
	"Paraguay",
	"Peru",
	"Philippines (the)",
	"Pitcairn",
	"Poland",
	"Portugal",
	"Puerto Rico",
	"Qatar",
	"Republic of North Macedonia",
	"Romania",
	"Russian Federation (the)",
	"Rwanda",
	"Réunion",
	"Saint Barthélemy",
	"Saint Helena, Ascension and Tristan da Cunha",
	"Saint Kitts and Nevis",
	"Saint Lucia",
	"Saint Martin (French part)",
	"Saint Pierre and Miquelon",
	"Saint Vincent and the Grenadines",
	"Samoa",
	"San Marino",
	"Sao Tome and Principe",
	"Saudi Arabia",
	"Senegal",
	"Serbia",
	"Seychelles",
	"Sierra Leone",
	"Singapore",
	"Sint Maarten (Dutch part)",
	"Slovakia",
	"Slovenia",
	"Solomon Islands",
	"Somalia",
	"South Africa",
	"South Georgia and the South Sandwich Islands",
	"South Sudan",
	"Spain",
	"Sri Lanka",
	"Sudan (the)",
	"Suriname",
	"Svalbard and Jan Mayen",
	"Sweden",
	"Switzerland",
	"Syrian Arab Republic",
	"Taiwan (Province of China)",
	"Tajikistan",
	"Tanzania, United Republic of",
	"Thailand",
	"Timor-Leste",
	"Togo",
	"Tokelau",
	"Tonga",
	"Trinidad and Tobago",
	"Tunisia",
	"Turkey",
	"Turkmenistan",
	"Turks and Caicos Islands (the)",
	"Tuvalu",
	"Uganda",
	"Ukraine",
	"United Arab Emirates (the)",
	"United Kingdom of Great Britain and Northern Ireland (the)",
	"United States Minor Outlying Islands (the)",
	"United States of America (the)",
	"Uruguay",
	"Uzbekistan",
	"Vanuatu",
	"Venezuela (Bolivarian Republic of)",
	"Viet Nam",
	"Virgin Islands (British)",
	"Virgin Islands (U.S.)",
	"Wallis and Futuna",
	"Western Sahara",
	"Yemen",
	"Zambia",
	"Zimbabwe",
	"Åland Islands"
];

const mapDispatchToProps = dispatch => ({
  AddTailorMade:(data)=>dispatch(AddTailorMade(data)),
  SnackBar:(show,message,varient)=>dispatch(SnackBar(show,message,varient)),
  stopStateEmpty:()=>dispatch(stopStateEmpty())
});


class Tailor extends Component {

    owlRef = React.createRef();
    featuredItemsRef = React.createRef();
    datesPicker = null;

    constructor(props) {


        super(props);

        this.state = {
          value: 0,
          numAdults:0,
          numChidren:0,
          numInfants:0,
          title:"Mr",
          first_name:"",
          last_name:"",
          email:"",
          contact_num:"",
          nationality:"",
          single:0,
          double:0,
          triple:0,
          duration:0,
          other_requesr:"",
          meal_basis:"FB",
          fullboard:"",
          halfboard:"",
          bedandbf:"",
          totpar: 0,
          start_date:"",
          end_date:"",
          start_date1:Date.parse(),
          end_date1:Date.parse(),


        }

        console.log(this.state.totpar)

    }
    handlePlusInfant=()=>{
      this.setState({
        numInfants:this.state.numInfants+1,
     })
    }

    handleMinInfant=()=>{
      if(this.state.numInfants!==0)
      {
        this.setState({
          numInfants:this.state.numInfants-1,
        })
      }
    }
    handledate(date) {
      this.setState({
        start_date: date
      })
    }

    handlePlusChild=()=>{
      this.setState({
        numChidren:this.state.numChidren+1,
     })
    }

    handleMinChild=()=>{
      if(this.state.numChidren !== 0)
      {
        this.setState({
          numChidren:this.state.numChidren-1,
        })
      }
    }

    handlePlusAdult=()=>{
      this.setState({
        numAdults:this.state.numAdults+1,
       // start_date1:this.datesPicker.selectedDates[0],
       // end_date1:this.datesPicker.selectedDates[1],
     })
    }

    handleMinAdult=()=>{
      if(this.state.numAdults!==0)
      {
        this.setState({
          numAdults:this.state.numAdults-1,
        })
      }
    }

    handleChange = name => event => {

        this.setState({
          [name]: event.target.value,
        });






    };

    single_num = (event) => {
      this.setState({
        single: event.target.value
      })
  }

  double_num = (event) => {
    this.setState({
      double: event.target.value
    })
}

triple_num = (event) => {
  this.setState({
    triple: event.target.value
  })
}

fullb = (event) => {
  this.setState({
    fullboard: true
  })
}

half = (event) => {
  this.setState({
    halfboard: true
  })
}

bb = (event) => {
  this.setState({
    bedandbf: true
  })
}



handleMeal = (field,basis) => {
  this.setState({
    [field]: basis,
  });
};



handleTitle = (event) => {
  this.setState({
    title: event.target.value
  })
}

handleNationality = (event) => {
  this.setState({
    nationality: event.target.value
  })
}



handledate=(event)=>{

  var datesPicker = new Datepickk()
      this.setState({

      start_date: (datesPicker.selectDate(new Date()))[0],
      end_date:(datesPicker.selectDate(new Date()))[1],
      start_date1: (datesPicker.selectDate(new Date()))[0],
      end_date1:(datesPicker.selectDate(new Date()))[1],

     });
}

// calculateDates=()=>{



//   var datesPicker = new Datepickk()
//   this.setState({

//   start_date1: (datesPicker.selectDate(new Date()))[0],
//   end_date1:(datesPicker.selectDate(new Date()))[1],

//  });

// }

handleAdd = () =>{
     if(this.state.start_date!== "" && this.state.end_date !== "")
     {

       if(this.state.first_name!==""){

        if(this.state.last_name !=="" ){



            if(this.state.email!==""){

             if(this.state.contact_num!==""){

               if(this.state.nationality!==""){

                if(this.state.meal_basis!==""){

                   if(this.state.single!==0||this.state.double!==0||this.state.triple!==0){

                     if(this.state.numAdults!==0)
                      {

                        this.props.AddTailorMade({
                          title:this.state.title,
                          first_name:this.state.first_name,
                          last_name:this.state.last_name,
                          email:this.state.email,
                          contact_no:this.state.contact_num,
                          nationality:this.state.nationality,
                          start_date:this.datesPicker.selectedDates[0],
                          end_date:this.datesPicker.selectedDates[1],
                          numAdults:this.state.numAdults,
                          numChidren:this.state.numChidren,
                          numInfants:this.state.numInfants,
                          participant:this.state.numAdults+this.state.numChidren+ this.state.numInfants,
                          meal_basis:this.state.meal_basis,
                          single:this.state.single,
                          double:this.state.double,
                          triple:this.state.triple,
                          duration:this.state.duration,
                          other_request:this.state.other_requesr,
                          rooms:parseInt(this.state.single)+parseInt(this.state.double)+parseInt(this.state.triple)


                        });

                        this.setState({
                          value: 0,
                          numAdults:0,
                          numChidren:0,
                          numInfants:0,
                          title:"Mr",
                          first_name:"",
                          last_name:"",
                          email:"",
                          contact_num:"",
                          nationality:"",
                          single:0,
                          double:0,
                          triple:0,
                          duration:0,
                          other_requesr:"",
                          meal_basis:"FB",
                          fullboard:"",
                          halfboard:"",
                          bedandbf:"",
                          totpar: 0,
                          start_date:"",
                          end_date:"",
                          start_date1:Date.parse(),
                          end_date1:Date.parse(),
                        })

                     }
                     else{
                      this.props.SnackBar(true,'Participents should be entered.','error');
                     }

                    }
                    else{
                      this.props.SnackBar(true,'Bed type should be entered.','error');
                    }
                 }
                 else{
                  this.props.SnackBar(true,'Meal Type should be entered.','error');
                 }

               }
               else{
                this.props.SnackBar(true,'Nationality should be entered.','error');
               }
              }
              else{
                this.props.SnackBar(true,'Contact number should be entered.','error');
              }
           }
           else{
            this.props.SnackBar(true,'Email should be entered.','error');
           }

          }
        else{
          this.props.SnackBar(true,'Second name should be entered.','error');
        }
      }
      else{
        this.props.SnackBar(true,'First name should be entered.','error');
      }
    }
       else{
         this.props.SnackBar(true,'Please select your travel dates','error');

       }




}

    componentDidUpdate(){
      if(this.props.stateNull===true)
      {
        this.props.stopStateEmpty()
      }
    }




    componentDidMount = () =>{
      var now = new Date();
        this.datesPicker = new Datepickk({
        container: document.querySelector('#datesPicker'),
        inline:true,
        range: true,

        highlight:{
       //  start: new Date(now.getFullYear(),now.getMonth(),3),

      //   end: new Date(now.getFullYear(),now.getMonth(),6),
          backgroundColor:'#c00',
          color:'#fff',
          legend: ''
        }
      });

      this.datesPicker.onSelect = ()=>{
        console.log(this.datesPicker.selectedDates);
      //  var datesPicker = new Datepickk()
        this.setState({

       start_date1:this.datesPicker.selectedDates[0],
       end_date1:this.datesPicker.selectedDates[1],
       start_date:this.datesPicker.selectedDates[0],
       end_date:this.datesPicker.selectedDates[1],

         });
      }
    }


    render() {
    
        return <Fragment>
			
        <Helmet>
          <meta charSet="utf-8" />
          <title>Bespoke Tours</title>
          {/* <meta name="description" content="description" />
          <meta property="og:title" content="og:title" />
          <meta property="og:type" content="article" />
          <meta property="og:url" content="og:url" />
          <meta property="og:image" content="og:image" /> */}
        </Helmet>
            <Headerinner/>
                <div className="contentwrap">
    <div className="title-wrap">
      <div className="container">
        <div className="row">
          <div className="col m12 s12">
            <div className="breadscrumb-wrap">
               <div className="nav-wrapper">
                <div className="row">
                <div className="col s12">
                  <a  href="/" style={{backgroundColor:"white",paddingTop:25}} className="breadcrumb">Home</a>
                  <a  href="TailorMade" style={{backgroundColor:"white",paddingTop:25}} className="breadcrumb">Bespoke Tours</a>
                  </div>

                </div>
                </div>
            </div>
            <div className="m-title"><h1>Bespoke Tours</h1></div>
          </div>
        </div>
      </div>
    </div>
    <div className="tour-details-form">
      <div className="container">
        <div className="row">
          <div className="col l7 m12 s12">
            <div className="tour-details">
              <div className="row">
                <div className="col s12">
                  <h3>Select Tour Details</h3>
                </div>
              </div>
              <div className="row">
                  <div className="col l6 m12 s12" >
                    <h5 >Select Arrival and Departure Date</h5>
                    <div className="calenderwrap">
                      <div id="datesPicker" className="calender"   ></div>
                      <div className="t-info tdue">
                        <h4>Tour Duration</h4>
                        <div className="f-row">
                          <div className="input-field col s12 tduration">
                            {/* <input name="group1" type="number" value={this.state.duration} onChange={this.handleChange('duration')} className="m-type" /> */}
                                {/* test only    startdate,enddate  */}

                            <div className="m-type"> { isNaN(this.state.start_date1)!==true && isNaN(this.state.end_date1)!== true ?   <Moment diff={this.state.start_date1} unit="days" onChange={(val) => { this.setState({duration:val})}}  >{this.state.end_date1}</Moment>:0 } </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col l6 m12 s12">
                   <div className="pttitle">
                     <h4>Participants</h4>
                   </div>

                    <div className="t-info">
                      <div  className="f-row partici">
                        <div className="t-people-row">
                          <div className="people-row">
                            <div className="p-ages"><h5>Adult</h5>
                              Age 12 - 99
                            </div>
                            <div className="p-counter-wrap">
                              <div className="handle-counter" id="handleCounter">
                              {/* className="counter-minus" */}
                                  <button onClick={()=>this.handleMinAdult()}>-</button>
                                  <input type="text"  className="counter-text"  value={this.state.numAdults} />
                                  <button className="counter-plus" onClick={()=>this.handlePlusAdult()}>+</button>
                              </div>
                            </div>
                          </div>

                          <div className="people-row">
                            <div className="p-ages"><h5>Child</h5>
                              Age 2 - 11
                            </div>
                            <div className="p-counter-wrap">
                              <div className="handle-counter" id="handleCounter2">
                                <button  onClick={()=>this.handleMinChild()}>-</button>
                                  <input type="text"  className="counter-text" value={this.state.numChidren} />
                                  <button className="counter-plus" onClick={()=>this.handlePlusChild()}>+</button>
                              </div>
                            </div>
                          </div>

                          <div className="people-row">
                            <div className="p-ages"><h5>Infant</h5>
                              Age 0 - 1
                            </div>

                            <div className="p-counter-wrap">
                              <div className="handle-counter" id="handleCounter3">
                                  <button  onClick={()=>this.handleMinInfant()}>-</button>
                                  <input type="text" value={this.state.numInfants}  className="counter-text" />
                                  <button className="counter-plus" onClick={()=>this.handlePlusInfant()} >+</button>
                              </div>
                            </div>
                          </div>
                      </div>

                      </div>
                    </div>
                    <div className="t-info">
                      <h4>Meal Type</h4>
                      <div className="row">
                        <div className={"input-field col s4 meal-box "+(this.state.meal_basis === 'FB' ? 'chks':'')} onClick={()=>this.handleMeal('meal_basis','FB')}   >

                          <label >Full Board</label>
                        </div>
                        <div className={"input-field col s4 meal-box "+(this.state.meal_basis === 'HB' ? 'chks':'')}  onClick={()=>this.handleMeal('meal_basis','HB')}   >
             
                          <label >Half Board</label>
                        </div>
                        <div className={"input-field col s4 meal-box "+(this.state.meal_basis === 'BNB' ? 'chks':'')} onClick={()=>this.handleMeal('meal_basis','BNB')} >

                          <label >Bed & Breakfast</label>
                        </div>
                      </div>
                    </div>
                    <div className="t-info">
                      <div className="rows">
                        <div className="col s12 m12" >
                              <div className="f-row" >
                                 <div className="input-field r-type" >
                                    <div>
                                      <table>
                                        <tr>
                                          <td><h4>Single</h4></td>
                                          <td><h4>Double</h4></td>
                                          <td><h4>Triple</h4></td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <Paper >
                                                <select  value={this.state.single}  onChange={this.single_num}    >
                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                </select>
                                            </Paper>
                                          </td>
                                          <td>
                                            <Paper>
                                                <select value={this.state.double}  onChange={this.double_num}   >
                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                </select>
                                            </Paper>
                                          </td>
                                          <td>
                                            <Paper>
                                                <select value={this.state.triple}  onChange={this.triple_num}   >
                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                </select>
                                            </Paper>
                                          </td>
                                        </tr>
                                      </table>

                                    </div>
                                </div>
                              </div>
                          </div>
                      </div>
                    </div>
                    <div className="t-info">
                      <div className="row">
                        <div className="col s12 textareas">
                          <h4>Other Request</h4>
                            <div className="f-row">
                              <div className="input-field">
                                <textarea id="textarea1" value={this.state.other_requesr} className="materialize-textarea"   onChange={this.handleChange('other_requesr')} ></textarea>
                              </div>
                            </div>
                        </div>
                      </div>


                    </div>
                </div>
                <div className="row">
                {/* <div className="col s12 textareas">
                  <h4>Other Request</h4>
                    <div className="f-row">
                      <div className="input-field">
                        <textarea id="textarea1" value={this.state.other_requesr} className="materialize-textarea"   onChange={this.handleChange('other_requesr')} ></textarea>
                      </div>
                    </div>
                </div> */}
              </div>
              </div>
            </div>
          </div>

          <div className="col l5 m12 s12">
            <div className="personal-details">
              <div className="row">
                <div className="col s12">
                  <h3>Personal Details</h3>
                </div>
              </div>
              <div className="row">
                <div className="col s12">
                  <div className="row">
                    <div className="input-field col s12 m12">
                      <table class="pdtable">
                        <tr><td><label>Title</label></td>
                            <td><label>First Name</label></td>
                            <td><label>Last Name</label></td>
                        </tr>
                        <tr><td>
                              <Paper>
                                  <select  value={this.state.title}  onChange={this.handleTitle }   >
                                      <option value="Mr">Mr.</option>
                                      <option value="Mrs">Mrs.</option>
                                      <option value="Miss">Miss.</option>
                                  </select>
                              </Paper>
                            </td>
                            <td class="f_name"><input id="first_name" value={this.state.first_name} onChange={this.handleChange('first_name')} type="text" className="validate" /></td>
                            <td className="l_name"><input id="last_name" value={this.state.last_name} onChange={this.handleChange('last_name')}  type="text" className="validate" /></td>
                        </tr>
                      </table>


                    </div>

                  </div>
                  <div className="row">
                    <div className="input-field col s12" >
                      <label>Email</label>
                      <input id="email" type="email" value={this.state.email} onChange={this.handleChange('email')}  className="validate" />

                    </div>
                  </div>
                  <div className="row">
                    <div className="input-field col s12">
                    <label>Contact No</label>
                      <input id="contact" type="text" value={this.state.contact_num} onChange={this.handleChange('contact_num')}className="validate" />

                    </div>
                  </div>
                  <div className="row">
                    <div className="input-field col s12">
                    <label>Nationality</label>
                        <select  
                          onChange={this.handleNationality }    >
                          <option value=""></option>
                          {countryList.map((item)=><option value={item}>{item}</option>)}
                        </select>

                    </div>
                  </div>
                  <div className="row">
                    <div className="input-field col s12">
                      <a  className="waves-effect booknw btn" onClick={()=>{this.handleAdd()}} >Book Now</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
            <Footer/>
            {this.props.stateNull===true ? window.location.reload(false) :null}
        </Fragment>
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Tailor);
