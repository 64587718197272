import { API_URL} from '../Actions/config';
import {startLoading,endLoading,SnackBar,handleHTTPError} from './CommonActions';
import store from '../Store';


export function GetCMS(cmsID){

    return function (dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/api/cms/'+cmsID+'/view',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
           //  'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token              
            }
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                    dispatch(storeActiveCMS(json.results));             
                                                                          
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Error loading locations','error'));
        });
        
    }
  }
  
  
  export function storeActiveCMS(results){
    return{type:'STORE_ACTIVE_CMS',cms:results};
  }
  
  
  