import React, { Component, Fragment } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import logo from '../img/logo.png';
import offers from '../img/menu-offers.jpg';
import { SearchActivities ,storeSearchKey,storeSearchKeyLock} from '../../Actions/SearchActions';
import { getSearchSuggestions } from '../../Actions/WebsiteActions';
import Alert from './Alert';
import {Link} from 'react-router-dom';

import { SnackBar } from '../../Actions/CommonActions';


const $ = window.jQuery;

const mapStateToProps = (state) => {
    return {
        CartItems:state.Cart.items,
        Key:state.Search.activeKey
    }
}

const mapDispatchToProps = dispatch => ({

  storeSearchKey:(Key)=>dispatch(storeSearchKey(Key)),
  storeSearchKeyLock:()=>dispatch(storeSearchKeyLock()),
  SearchActivities:(key)=>dispatch(SearchActivities(key)),
  getSearchSuggestions:(key)=>dispatch(getSearchSuggestions(key)),
  SnackBar : (show,message,varient) => dispatch(SnackBar(show,message,varient))

});







class HeaderInner extends Component {

    HeaderPanelRef = React.createRef();
    featuredItemsRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            value: 0,
            searchField:"",
            searchOnFocus:false,
            scrolled:false,
            place:"Search for “Sigiriya Rock” or “Kandy” ",
            cart:"/cart"
        }

    }


    static getDerivedStateFromProps(props, state) {

      if (props.Key.updated) {

        props.storeSearchKeyLock();
        return {
          searchField: props.Key.searchKey.Key
        }
      }
    }


    componentDidMount = () =>{

      $('.hamburger').click(function() {
        $('.menu').toggleClass('open-menu');
        $('body').toggleClass('overlay');
        $('body').toggleClass('menuon');
    });

      $(window).scroll(()=>{
        var $nav = $(this.HeaderPanelRef.current);
        this.setState({
            scrolled:$nav.toggleClass('scrolled', $(window).scrollTop() > $nav.height())
        })
    });


    }

    handleChange = (event,field) => {

      this.setState({
        [field]:event.target.value
      },()=>{

        //this.props.getSearchSuggestions(this.state.searchField);
        

      });
    }

    
    cartView(value){
      if(value===0)
      {
        this.props.SnackBar(true,"You don't have anything in the cart","error")
      }
      
    }

    handleEnterKey = (e) =>{
        if (e.key === 'Enter') {

              window.location.hash = "#uploadImgSection";
              window.location.href="/SearchResult?key="+this.state.searchField;
              window.location.Key=this.state.searchField;
               e.preventDefault();

              this.props.SearchActivities(this.state.searchField)
            }


            if(this.state.searchField!=="")
            {
              this.props.storeSearchKey(this.state.searchField)
            }
    }

    handleSearchButton = () => {

      if(this.state.searchField!=="")
      {
        window.location.hash = "#uploadImgSection";
        window.location.href="/SearchResult?key="+this.state.searchField;
        window.location.Key=this.state.searchField;
      }else{
        window.location.hash = "#uploadImgSection";
        window.location.href="/SearchResult";
        window.location.Key=this.state.searchField;
      }
    }

    componentDidUpdate(prevProps, prevState) {
      // only update chart if the data has changed
      
    }



    render() {
        return(

<Fragment>
<Alert/>
<div className="overlays"></div>
  <header className={"header-wrap inner "+(this.state.scrolled === true ? 'scrolled':'')} ref={this.HeaderPanelRef}>
    <div className="header-top">
      <div className="container">
        <div className="row">
          <div className="col m12 s12">
            <div className="hwrap">
               <div className="logo-wrap">
                  <a href="/"><img src={logo} alt=""></img></a>
               </div>
               <div className="input-field col s6 top-search" >
                   <input type="text" id="search-input" className="autocomplete" placeholder={this.state.place} value={this.state.searchField}  onBlur={()=>{ this.setState({searchOnFocus:false,place:"Search for “Sigiriya Rock” or “Kandy” "}) }}  onFocus={()=>{ this.setState({searchOnFocus:true,place:""}) }} onKeyPress={(e)=>this.handleEnterKey(e)} onChange={(e)=>{ this.handleChange(e,'searchField'); }}   onClick={(e)=>{ this.handleChange(e,'searchField'); }}    />
                   <a class="btnsearch" onClick={()=>this.handleSearchButton()}></a>
               </div>



            </div>
             <div className="header-col">
                <div className="header-icons">

                   {/* <div className="mobisearch">
                    <button className="mobisearch" type="button" >
                      <span className="mobisearch">
                        <span className="mobisearch"></span>
                      </span>
                    </button>
                  </div>  */}

                  <div class="mobisearch"></div>
 

                    <Link onClick={()=>this.cartView(this.props.CartItems.length)} to={this.props.CartItems.length!==0 ? this.state.cart :null } className="tour-count">
                      <i className="sprite sprite-cart"></i><span>{this.props.CartItems.length}</span>
                    </Link>
                </div>
                <button className="hamburger hamburger--squeeze" type="button" >
                  <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                  </span>
                </button>
              </div>
          </div>
        </div>
      </div>
  </div>

 <div className="menu">
    <div className="menuwrap">
      <ul>
       <li ><a href="/Aboutus">About us</a></li>
         <li><a href="/SearchResult?tourTypes=1">Day Tours </a></li>
         <li><a href="/SearchResult?tourTypes=2">Multi Tours</a></li>
         <li><a href="/SearchResult?tourTypes=3">Overnight Experience </a></li>
       <li><a href="/TailorMade">Taiormade Tours </a></li>
      </ul>
      <div className="sublinks">
        <a href="/Terms">Terms & Condition</a>
        <div className="sublinkwrap">
            <a href="/Privacy">Privacy Policy </a> | <a href="/Cookies">Cookies Policy</a>
        </div>
      </div>
      <div className="nav-offer">
        <h3>Special offer</h3>
      </div>
      <div className="special-offers">
      <img src={offers} alt=""></img>

      </div>
    </div>
   </div>
   <div style={{clear:'both'}}></div>
</header>






</Fragment>



        ) ;
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(HeaderInner);
