export default function reducer(state={
    activeTour:{
                from_date:new Date(),
                location_id:"",
                adults:0,
                children:0,
                infants:0,
                Update:false
                }
},action){
    
switch(action.type){
    case "STORE_TOUR_DETAILS":{
        return{...state,activeTour:{
            from_date:action.list.from_date,
            location_id:action.list.location_id,
            adults:action.list.adults,
            children:action.list.children,
            infants:action.list.infants,
            Update:true
            }}
    } 
    case "LOCK_STORE_TOUR_DETAILS":{
        return{...state,activeTour:{Update:false
                }}
    } 
}
return state
}