import React, { Component, Fragment } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';

import Alert from './Alert';
import {Link} from 'react-router-dom';
import { SnackBar } from '../../Actions/CommonActions';

const $ = window.jQuery;

const mapStateToProps = (state) => {
  return {
      CartItems:state.Cart.items,
  }
}

const mapDispatchToProps = dispatch => ({
  SnackBar : (show,message,varient) => dispatch(SnackBar(show,message,varient))

});

class header extends Component {

  HeaderPanelRef = React.createRef();
  featuredItemsRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      windowTop: 0,
      navHeight:0,
      scrolled:false,
      cart:"/cart"
    }

  }

  componentDidMount = () => {

    $('.hamburger').click(function() {
      $('.menu').toggleClass('open-menu');
      $('body').toggleClass('overlay');
      $('body').toggleClass('menuon');
  });

    $(window).scroll(() => {
      this.setState({
        windowTop: $(window).scrollTop(),
        navHeight:this.HeaderPanelRef !== null? this.HeaderPanelRef.getBoundingClientRect().height:null
      })
    });


  }


  handleChange = (event) => {
    this.setState({
      value: event.target.value
    })
  }
  
  cartView(value){
    if(value===0)
    {
      this.props.SnackBar(true,"You don't have anything in the cart","error")
    }
    
  }


  render() {
    return (<Fragment>
    <Alert/>
      <div className="overlays"></div>
      <header className={"header-wrap "+(this.state.windowTop > this.state.navHeight ? 'scrolled':'')}  ref={(header)=>{ this.HeaderPanelRef = header}}>
        <div className="header-top">
          <div className="container">
            <div className="row">
              <div className="col m12 s12">
                <div className="logo-wrap">
                  <a href="/"><img src="img/logo.png" alt="" /></a>
                </div>
                <div className="header-col">
                  <div className="header-icons">
                    <Link onClick={()=>this.cartView(this.props.CartItems.length)} to={this.props.CartItems.length!==0 ? this.state.cart :null } className="tour-count"><i className="sprite sprite-cart"></i><span>{this.props.CartItems.length}</span></Link>
                  </div>
                  <button className="hamburger hamburger--squeeze" type="button">
                    <span className="hamburger-box">
                      <span className="hamburger-inner"></span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="menu">
          <div className="menuwrap">
            <ul>
              <li><a href="/Aboutus">About us</a></li>
              <li><a href="/SearchResult?tourTypes=1">Day Tours </a></li>
              <li><a href="/SearchResult?tourTypes=2">Multi Tours</a></li>
              <li><a href="/SearchResult?tourTypes=3">Overnight Experience </a></li>
              <li><a href="/TailorMade">Taiormade Tours </a></li>
            </ul>
            <div className="sublinks">
              <a href="">Terms & Condition</a>
              <div className="sublinkwrap">
                <a href="">Privacy Policy </a> | <a href="">Cookies Policy</a>
              </div>
            </div>
            <div className="nav-offer">
              <h3><a href="#">Special offer</a></h3>
            </div>
            <div className="special-offers">
              <a href="#"><img src="img/menu-offers.jpg" alt="" /></a>

            </div>
          </div>
        </div>

      </header>

    </Fragment>
    );
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(header);
