import { API_URL} from '../Actions/config';
import {startLoading,endLoading,SnackBar,handleHTTPError} from './CommonActions';


export function fetchLocations(){

    return function (dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/api/location/get',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            }
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                if(json.httpCode === 200){
                  dispatch(storeLocations(json.results));
                }

                    dispatch(endLoading());
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Error loading locations','error'));
        });

    }
}


export function storeLocations(locations){
    return{type:'STORE_LOCATIONS',locations:locations};
}
