import {API_URL} from './config';
import {startLoading,endLoading, SnackBar,handleHTTPError} from './CommonActions';
import store from '../Store';





export function TopSellingActivities(limit,tourTypeID,tourType) {

    return function(dispatch) {
        dispatch(SnackBar(true,'Updating...','info'));

        fetch(API_URL+'/api/search?tour_type='+tourTypeID+'&limit='+limit,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },

        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{

                  dispatch(endLoading());
                  dispatch(storeTopSellingActivities(json.results,tourType));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }

}


export function storeTopSellingActivities(list,tourType) {
    return {
        type: 'STORE_TOP_SELLING',
        list: list,
        tourType:tourType
    }
}


export function DealsActivities() {

    return function(dispatch) {
        dispatch(SnackBar(true,'Updating...','info'));

        fetch(API_URL+'/api/search?deals=1&limit=8',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },

        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(storeDealsActivities(json.results));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }

}

export function storeDealsActivities(list) {
    return {
        type: 'STORE_DEALS',
        list: list
    }
}



export function GetActivity(activityID) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/activity/'+activityID+'/get',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },

        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                 dispatch(storeActiveActivity(json.results));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }

}

export function StorewAvailability(avl) {
    console.log(avl,"2222status")
    return {
        type: 'STORE_AVAILABILITY',
        availability: avl
    }
}


export function GetAvailability(activityID,date) {

    return function(dispatch) {
        let quetyString = "";

        if(date !== null && date !== undefined){
            quetyString = quetyString+"?pickup_date="+date;
        }
        dispatch(startLoading());
        fetch(API_URL+'/api/activity/'+activityID+'/check-availability'+quetyString,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            }

        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  console.log(json.status,"json.statusjson.status")
                 dispatch(StorewAvailability(json.status));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }

}

export function storeActiveActivity(act) {
    return {
        type: 'STORE_ACTIVE_ACTIVITY',
        activity: act
    }
}


export function GetCostEstimation(date,adults,children,infants,pickupLocation,activityId) {
        return function(dispatch) {
          let quetyString = "";

          if(date !== null && date !== undefined){
              quetyString = quetyString+"?pickup_date="+date;
          }

          if(adults !== 0 && adults !== "" && adults !== null && adults !== undefined){
              if(quetyString === ""){
                  quetyString = quetyString+"?adults="+adults;
              }else{
                  quetyString = quetyString+"&adults="+adults;
              }
          }

          if(children !== 0 && children !== "" && children !== null && children !== undefined){
              if(quetyString === ""){
                  quetyString = quetyString+"?children="+children;
              }else{
                  quetyString = quetyString+"&children="+children;
              }
          }

          if(infants !== 0 && infants !== "" && infants !== null && infants !== undefined){
              if(quetyString === ""){
                  quetyString = quetyString+"?infants="+infants;
              }else{
                  quetyString = quetyString+"&infants="+infants;
              }
          }

          if( pickupLocation !== "" && pickupLocation !== null && pickupLocation !== undefined){
              if(quetyString === ""){
                  quetyString = quetyString+"?pickup_location="+pickupLocation;
              }else{
                  quetyString = quetyString+"&pickup_location="+pickupLocation;
              }
          }

          dispatch(startLoading());
          fetch(API_URL+'/api/activity/'+activityId+'/get-price'+quetyString,{
              method: 'GET',
              headers: {
                'Content-Type': 'application/json;charset=utf-8',
              },

          }).then(handleHTTPError)
          .then((response)=>{
                dispatch(endLoading());
                response.json().then((json)=>{
                    dispatch(endLoading());
                    dispatch(updateSelectedEstimation(json));
                });
          }).catch((error)=>{
            dispatch(endLoading());
            dispatch(SnackBar(true,'Could not load data','error'));
          });

        }

    }


    export function UpdateCartItemEstimation(date,adults,children,infants,pickupLocation,activityId,itemIndex) {
        return function(dispatch) {
          let quetyString = "";

          if(date !== null && date !== undefined){
              quetyString = quetyString+"?pickup_date="+date;
          }

          if(adults !== 0 && adults !== "" && adults !== null && adults !== undefined){
              if(quetyString === ""){
                  quetyString = quetyString+"?adults="+adults;
              }else{
                  quetyString = quetyString+"&adults="+adults;
              }
          }

          if(children !== 0 && children !== "" && children !== null && children !== undefined){
              if(quetyString === ""){
                  quetyString = quetyString+"?children="+children;
              }else{
                  quetyString = quetyString+"&children="+children;
              }
          }

          if(infants !== 0 && infants !== "" && infants !== null && infants !== undefined){
              if(quetyString === ""){
                  quetyString = quetyString+"?infants="+infants;
              }else{
                  quetyString = quetyString+"&infants="+infants;
              }
          }

          if( pickupLocation !== "" && pickupLocation !== null && pickupLocation !== undefined){
              if(quetyString === ""){
                  quetyString = quetyString+"?pickup_location="+pickupLocation;
              }else{
                  quetyString = quetyString+"&pickup_location="+pickupLocation;
              }
          }

          dispatch(startLoading());
          fetch(API_URL+'/api/activity/'+activityId+'/get-price'+quetyString,{
              method: 'GET',
              headers: {
                'Content-Type': 'application/json;charset=utf-8',
              },

          }).then(handleHTTPError)
          .then((response)=>{
                dispatch(endLoading());
                response.json().then((json)=>{
                    dispatch(endLoading());
                    
                    dispatch(updateSelectedItemEstimation(json,itemIndex));
                });
          }).catch((error)=>{
            dispatch(endLoading());
            dispatch(SnackBar(true,'Could not load data','error'));
          });

        }

    }




export function storeActivities(list) {
    return {
        type: 'STORE_ACTIVITIES',
        list: list
    }
}







export function LockActiveActivity(act) {
    return {
        type: 'LOCK_ACTIVE_ACTIVITY',
    }
}


export function updateSelectedEstimation(est) {
    return {
        type: 'UPDATE_ESTIMATION',
        pickuplocations:est, 
    }
}

export function updateSelectedItemEstimation(est,itemIndex) {
    return {
        type: 'UPDATE_CART_ESTIMATION',
        pickuplocations:est,
        index: itemIndex
    }
}


export function LockEstimateUpdate() {
    return {
        type: 'STOP_UPDATE_ESTIMATION'
    }
}



export function SearchActivities(queryString,store) {

    return function(dispatch) {
        dispatch(SnackBar(true,'Updating your results','info'));

        fetch(API_URL+'/api/search'+queryString,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },


        }).then(handleHTTPError)
        .then((response)=>{

                dispatch(endLoading());
            response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(SnackBar(false,'Updating...','info'));
                  dispatch(storeSearchActivities(json.results,store));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }

}



export function storeSearchActivities(list,store) {

    return {
        type: 'STORE_SEARCH_ACTIVITIES',
        list: list,
        store:store
    }
}



export function FeaturedActivities() {

    return function(dispatch) {
        dispatch(SnackBar(true,'Updating...','info'));

        fetch(API_URL+'/api/search?featured=1&limit=5',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },

        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(storeFeaturedActivities(json.data.results));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }

}

export function storeFeaturedActivities(list) {
    return {
        type: 'STORE_FEATURED',
        list: list
    }
}






export function TopSuggestedActivities() {

    return function(dispatch) {
        dispatch(SnackBar(true,'Updating...','info'));

        fetch(API_URL+'/api/search?limit=10',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },

        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(storeTopSuggestedActivities(json.data.results));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }

}


export function storeTopSuggestedActivities(list) {
    return {
        type: 'STORE_SEARCH_SUGGESTIONS',
        locations:[],
        activities:list

    }
}

export function RelatedActivities(cats) {

    return function(dispatch) {
        dispatch(SnackBar(true,'Updating...','info'));

        fetch(API_URL+'/api/search?category='+cats+'&limit=3',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },

        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(storeRelatedActivities(json.result));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }

}

export function storeRelatedActivities(list) {
    return {
        type: 'RELATED_TOURS',
        list: list
    }
}
