import React, {PureComponent, Fragment} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';


const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = dispatch => ({});


class PricingWidgetLoc extends PureComponent {
  
  owlRef = React.createRef();
  featuredItemsRef = React.createRef();
  
  constructor(props) {
    super(props);
    this.state = {
      locName:"",
      openPanel:false,
      selectedLocation:null,
      data:true,
      location:null,
      locationUpdated:true
    }
  }


  componentDidMount= ()=> {}

  
  componentDidUpdate = (prevProps) => {

    
     if(this.props.value !== null && this.props.fieldName !== null && this.props.loc.length > 0 && this.state.locationUpdated === true ){
        
        for(let x=0; x < this.props.loc.length; x++){
            if(this.props.loc[x][this.props.field].toString() === this.props.value.toString()){
              this.setState({
                locationUpdated:false
              },()=>this.props.handleChangeLoc(this.props.loc[x]))  
              
            }
        }
     } 


    this.array = this.props.loc[0];
    if(this.props !== prevProps){
      if(this.state.selectedLocation===null||this.state.selectedLocation===""){
        this.setState({
          locName: this.array !== undefined ? this.array.location_name : "" ,
          selectedLocation:this.array !== undefined ? this.props.loc[0].location_id : "",
        })}
     }

    if(this.state.data === true){

        this.setState({
          data:!this.state.data,
        })
       this.props.updateCosting()
      }
  }


  openOptions = () =>{
    this.setState({
      openPanel: !this.state.openPanel
    });
  }

  closeOptions = () =>{ 
    this.setState({
      openPanel: false
    },this.props.handleChangeLoc(this.state.location));

  }

  handleChangeLoc = (loc) => {
    this.setState({
      locName: loc.location.name,
      selectedLocation:loc.location.id,
      location:loc,
      
    },()=>this.props.handleChangeLoc(loc))

   // this.props.handleChangeLoc(loc)
  }


  handleUpdate = () =>{
    if(this.state.selectedLocation !== null){
        this.props.handleChangeLoc(this.state.selectedLocation);
        this.closeOptions();
    }
  }

  render() {
    return (<Fragment>
      <div className="row">
        <div className="input-field col m12 s12 tr-pickup"  onClick={()=>this.openOptions()}>
          <input type="text" readOnly value={this.state.locName}  className="focus_field"  placeholder="Pickup Location"/>
        </div>
        <div className={"hide_block travel-pickups"+(this.state.openPanel ? ' opn':'')}>
          <RadioGroup name="position" value={this.state.selectedLocation}>
            {this.props.loc.length > 0  ? this.props.loc.map((o, index) => {
                return <div className="travelpickwrap">
                  <div id="tdatepicker" className="tpickup-row">
                    <div className="pickuphead" onClick={() => this.handleChangeLoc(o)} >
                      <h4>{o.location.name}</h4>
                      <div className="pik-select">
                        <label>
                          <FormControlLabel 
                          value={o.location.id} 
                          onClick={() => this.handleChangeLoc(o)} 
                          control={<Radio color = "black" />} 
                          checked={this.state.selectedLocation === o.location.id} 
                          />
                          <span></span>
                        </label>
                      </div>
                    </div>
                    <div className="tourt-price">
                      <span className="tp-col1">
                        <b>Total</b>:<br/>
                        <h4>USD {o.amount_formatted}</h4>
                      </span>

                      <span className="tp-col2">
                        <b>Time</b>:<br/>
                        <h4>{o.pickup_time}</h4>
                      </span>

                    </div>

                  </div>
                </div>

              })
            :null}
          </RadioGroup>
          <a onClick={()=>this.closeOptions()} className="waves-effect waves-light btn updatebtn">update</a>
        </div>
      </div>

    </Fragment>);
  }
}

PricingWidgetLoc.defaultProps ={
  value:null,
  field:null
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(PricingWidgetLoc);
