import React, {  Component, Fragment } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import mastercard from '../img/mastercard.png';
import visa from '../img/visa.png';
import footerlogo from '../img/footer-logo.png';




const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = dispatch => ({

});







class Footer extends Component {

    owlRef = React.createRef();
    featuredItemsRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            value: 0
        }

    }




    handleChange = (event) => {
        this.setState({
            value: event.target.value
        })
    }


    render() {
        return (<Fragment>

<footer>
  	<div className="footerwrap">
      <div className="socialwrap">
        <div className="container">
          <div className="row">
            <div className="col l3 m3 s12">
              <div className="socilalinks">
                <ul>
                  <li><a href=""><i className="sprite tw-social-icon"></i></a></li>
                  <li><a href=""><i className="sprite fb-social-icon"></i></a></li>
                  <li><a href=""><i className="sprite ins-social-icon"></i></a></li>
                  <li><a href=""><i className="sprite youtube-social-icon"></i></a></li>
                </ul>

              </div>
            </div>
            <div className="col l6 m6 s12">
              <div className="payments">
              <img  src={mastercard} alt=""></img>
                <img  src={visa} alt=""></img>
              </div>
            </div>
            <div className="col l3 m3 s12">
               <a style={{color:"white"}} href="/BespokeTours" className="helpbtn waves-effect waves-light btn">Help Me to Plan My Holiday</a>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="container">
            <div className="row">
              <div className="col m12 s12">
                <div className="row">
                  <div className="col m4 s12">
                  <a href="/" className="flogo"><img src={footerlogo} alt=""></img></a>
                  </div>

                  <div className="col m4 s12">
                    <div className="flinks">
                      <p>2019 Edwin Travels (Pvt) Ltd</p>
                      <a href="./Terms">Terms & Conditions</a> <a href="./Privacy">Privacy Policy</a> <a href="/Cookies">Cookie policy</a>
                    </div>
                  </div>


                  <div className="col m4 s12">
                    <div className="f-contacts">
                      <p>email : <a href="mailto:info@edwintravels.com">info@edwintravels.com</a></p>
                      <p>Hotline : <a href="tel:+94 112 112112">+94 112 112112</a> | <a href="tel:+94 776 565465">+94 776 565465</a></p>
                    </div>
                      </div>




                </div>
              </div>
            </div>
          </div>
        </div>
  	</div>
    <div ref={el => (this.div = el)}>
    </div>
  </footer>








        </Fragment>);
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Footer);
