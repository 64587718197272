export default function reducer(state={
    list: new Array(),
    searchResultsLocations:[],
    searchResultsActivities:[],
    dealsDiscountedTours:[],
    mybookings:{
     current_page: null,
     data: [],
     first_page_url: null,
     from: null,
     last_page: null,
     last_page_url: null,
     next_page_url: null,
     path: null,
     per_page: 12,
     prev_page_url: null,
     to: null,
     total: null,
    },

    activeBooking:{

                     activity:null,
                     activity_id: null,
                     booking_reference: null,
                     contact_email: null,
                     contact_firstname: null,
                     contact_lastname: null,
                     contact_phone: null,
                     created_at: null,
                     deleted_at: null,
                     end_date: null,
                     hotel_address: null,
                     hotel_name: null,
                     id: 0,
                     num_adults: 0,
                     num_children: 0,
                     num_infants: 0,
                     payment_amount: null,
                     payment_reference: null,
                     pickup_location: null,
                     remarks: null,
                     start_date: null,
                     status: null,
                     updated_at: null,
                     user_id: null
                  },
     myreviews:[],
     BookingMessages:[],
     supplierSignUpComplete:false,
     clientSignUpComplete:false,
     featuredTours:[],
     topSellingDayTours:[],
     topSellingMultiDayTours:[],
     dealsDiscountedTours:[],
     sliders:[],
     featuredCategories:[],
     site_title:"",
     site_description:"",
     site_keywords:"",
     site_featured:0,
     site_aboutus:"",
     site_privacy:"",
     site_cookie:"",
     site_disclaimer:"",
     site_guidelines:"",
     dealAndDiscount:null,
     stateNull:false,
     bookingConfirmed:false

 },action){
 switch(action.type){
     case "STORE_SEARCH_SUGGESTIONS":{
         return{...state,searchResultsLocations:action.locations,searchResultsActivities:action.activities}
     }
     case "CLEAR_SEARCH_SUGGESTIONS": {
         return{...state,searchResultsActivities:action.activities}
     }
     case "STORE_MY_BOOKINGS":{
         return{...state,mybookings:action.list}
     }
     case "STORE_ACTIVE_BOOKING":{
         return{...state,activeBooking:action.booking}
     }
     case "STORE_REVIEWS":{
         return{...state,myreviews:action.reviews}
     }
     case "STORE_ACTIVE_BOOKING_MESSAGES":{
         return{...state,BookingMessages:action.messages}
     }
     case "SIGNUP_COMPLETE":{
         return{...state,[action.field]:true}
     }
     case "STORE_FEATURED":{
         return{...state,featuredTours:action.list}
     }
     case "STORE_TOP_SELLING":{
         return{...state,[action.tourType]:action.list}
     }
     case "STORE_DEALS":{

         return{...state,dealsDiscountedTours:action.list}
     }
     case "STORE_SLIDERS":{
         return{...state,sliders:action.slides}
     }
     case "STORE_FEATURED_CATS":{
         return{...state,featuredCategories:action.cats}
     }
     case "STORE_SITE_SETTINGS":{
         return{...state,site_title:action.settings.title,
                         site_description:action.settings.description,
                         site_keywords:action.settings.keywords,
                         site_featured:action.settings.featured
                     }
     }
     case "STORE_SITE_CONTENT":{
         return{...state,site_aboutus:action.settings.aboutus,
                         site_privacy:action.settings.privacy,
                         site_cookie:action.settings.cookie,
                         site_disclaimer:action.settings.disclaimer,
                         site_guidelines:action.settings.guidelines
                     }
     }
     case "STORE_DEAL_AND_DISCOUNT":{
         return{
            ...state,dealAndDiscount:action.dealAndDiscount,
         }
     }
     case "STATE_NULL":{
         return{
            ...state,stateNull:true,
         }
     }
     case "STOP_STATE_NULL":{
         return{
            ...state,stateNull:false,
         }
     }
     case "BOKING_CONFIRMED":{
        return{
           ...state,bookingConfirmed:true,
        }
    }
     default:
         break
 }

 return state
 }
