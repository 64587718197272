import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import EditPanel from './CartEdit';

const currencyFormat = (num) => {  
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const mapStateToProps = (state) => {
  return {

  }
}

const mapDispatchToProps = dispatch => ({

});

class CartItem extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showEdit: false
    }

  }

  getDuration(measure) {
    switch (measure) {
      case 1: { return "Days" }
      case 2: { return "Hours" }
      case 3: { return "Minutes" }
    }
  }

  formatDate = (date) => {
    var monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
    ];

    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();

    return day + ' ' + monthNames[monthIndex] + ' ' + year;
  }

  handleShowEditPanel = () =>{
      this.setState({
          showEdit:!this.state.showEdit
      });
  }

  callBack = (value) => {
    this.setState({
      showEdit:value
  });
  }

  render() {

    const { data } = this.props;
    const { itemIndex } = this.props;

    return <div className="cart-item-box">
      <a className="ct-close" onClick={() => { this.props.onRemoveItem(itemIndex) }}><i className="Small material-icons">close</i></a>
      <div className="cart-date">Tour #{itemIndex + 1}</div>
      <div className="cart-item-info">
        <div className="cart-item-thumb-wrap">
          <div className="cart-item-thumb">
            <img src={data.activity.activityObject.thumb_image} width="115" height="auto" alt="" />
          </div>
          <div className="options-wrap">
            <a onClick={()=>this.handleShowEditPanel()}>Edit</a>
            <a googl="true" onClick={() => { this.props.onRemoveItem(itemIndex) }}>Remove</a>
          </div>
        </div>
        <div className="cart-item-details">
          <div onClick={() => { window.location.href = "/tour/show/" + data.activity.id }}> <h3>{data.activity.name}</h3></div>
          <div className="date-info">{data.from_date !== "" ? this.formatDate(new Date(data.from_date)) : null}</div>
          <div className="no-cilents">{data.adults} Adults {data.children !== null ? (data.children + ' Children') : null} {data.children !== null ? (data.infants + ' Infants') : null}</div>
          <span className="tour-location">{data.activity.activityObject.location !== undefined ? data.activity.activityObject.location.name : null}</span>
        </div>
      </div>
      <EditPanel callBack={this.callBack} open={this.state.showEdit} data={data} itemIndex={itemIndex} />
      <div style={{clear:"both"}}></div>
      <div className="cart-price-wrap"> 
        <p>${currencyFormat(data.amount)}</p>
      </div>

    </div>;
  }
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(CartItem);
