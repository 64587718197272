import React, { Props, Component, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Headerinner from './common/HeaderInner';
import Footer from './common/Footer';
import  '../css/responsive1.css';
import  '../css/custom-styles1.css';
import aboutimg from './img/about.png';
import {Helmet} from "react-helmet";
import { GetCMS } from '../Actions/CmsActions';

const mapStateToProps = (state) => {
    return {
      CMS: state.CMS.activecms,
    }
}

const mapDispatchToProps = dispatch => ({

  fetchAbout: (id) => dispatch(GetCMS(id)),

});







class PaymentConfirm extends Component {




    constructor(props) {
      super(props);

      //console.log(parsedS)




      this.state = {





      }

  //  this.props.fetchAbout(18);

    this.props.fetchAbout(props.cid);

    }





    render() {
      
      return (<Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Payment Confirmed</title>						
        </Helmet>
        <Headerinner></Headerinner>
        <div className="contentwrap">
    <div className="title-wrap">
      <div className="container">
        <div className="row">
          <div className="col m12 s12">
            <div className="breadscrumb-wrap">
               <div className="nav-wrapper">
                <div className="row">
                  <div className="col s12">
                    <a href="/" className="breadcrumb">Home</a>
                    <a href="#!" className="breadcrumb">{this.props.CMS.title}</a>
                  </div>
                </div>
                </div>
            </div>
      <div className="m-title"><h1>{this.props.CMS.title}</h1></div>
          </div>
        </div>
      </div>
    </div>
    <div className="aboutwrap">
      <div className="container">
        <div className="row">
          <div className="col m7">
            <div className="abouttxt">
            <p>{this.props.CMS.content}</p>

            </div>
          </div>
          <div className="col m5">
            <div className="abt-img">
              {console.log(this.props.CMS.image)}
              {this.props.CMS.image !== null && this.props.CMS.image !== undefined ? <img src={this.props.CMS.full_image} alt="Logo" className="responsive-img" />:null }

               {/* <img src="../img/about.png" alt="" className="responsive-img"></img> */}
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>




        <Footer></Footer>

      </Fragment>
      );
    }
  }

export default compose(connect(mapStateToProps, mapDispatchToProps))(PaymentConfirm);
