import React, {  Component, Fragment } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom'

import { updateUser,validateUser } from '../Actions/AuthActions';
import {Helmet} from "react-helmet";
import { SnackBar } from '../Actions/CommonActions';





const mapStateToProps = (state) => {
return {

loading : state.Auth.loading ,
loggedIn : state.Auth.loggedIn
}
}

const mapDispatchToProps = dispatch => ({
    validateUser : (username,password) => dispatch(validateUser(username,password)),
    forgotPass :(email) => dispatch(updateUser(email)),
SnackBar : (show,message,varient) => dispatch(SnackBar(show,message,varient))
});

class FrogotPass extends Component{

constructor(props){
super(props);

this.state = {
Email: "",
password:"",
loggedIn:false,
loading:false,
username:''
};
}


handleChange = name => event => {
this.setState({
[name]: event.target.value,
});
};


forgot(){
        if(this.state.Email.length == 0){
            this.props.SnackBar(true,"We need your Email",'error');
        return false;
        }
        console.log(this.state)
        this.props.forgotPass(this.state.Email);

        }

componentDidMount() {
window.scrollTo(0,0)
}


render(){
return <Fragment>
<Helmet>
  <meta charSet="utf-8" />
  <title>Forgot Password</title>
  {/* <meta name="description" content="description" />
  <meta property="og:title" content="og:title" />
  <meta property="og:type" content="article" />
  <meta property="og:url" content="og:url" />
  <meta property="og:image" content="og:image" /> */}
</Helmet>

<section className="gry-log">
<div className="row">
<div className="container">
<div className="col l12 m12 s12">
<div className="log-head">
<h1>Forgot Password</h1>

</div>
</div>
</div>
</div>
</section>
<section className="cont-log">
<div className="row">
<div className="container">
<div className="col l12 m12 s12">
<div className="log-head">

<div className="inp">
            <label>Email Address</label>
                <input type="text" value={this.state.Email} onChange={this.handleChange('Email')} />
</div>
<div className="log-btn">
<i className="waves-effect waves-light btn">
<input type="submit" value="Search" onClick={()=>{ this.forgot();}} />
</i>

</div>
<div className="hv-acunt">
<p>Do have an account?  <Link to={'/'}>Sign Up</Link> </p>
</div>
</div>
</div>
</div>
</div>
</section>


</Fragment>
}
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(FrogotPass);