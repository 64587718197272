import React, { Component} from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';


const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = dispatch => ({

});

class ItineraryBlock extends Component {

  constructor(props) {
      super(props);

      this.state = {
        showMore :false
      }
  }
  getDuration(measure){
    switch(measure){
      case 1 :{ return "Days" }
      case 2 :{ return "Hours" }
      case 3 :{ return "Minutes" }
    }
  }

    toggleShowMore = () =>{
        this.setState({
            showMore:!this.state.showMore
        });
    }

    render() {

        const { data } = this.props;
        

        return <div className="itinerary-block">
          <div className="day-no"><h6>{data.time_label}</h6></div>

          <div className={"iti-block "+(this.state.showMore === true ? 'opn':"")}>
            <h2>{data.label}</h2>
            <a className="seemore" onClick={()=>this.toggleShowMore()}>+</a>
            <div className="itinery-more">

               <p>{data.summary}</p>
            </div>


            <div className="itinerary-duration">
              {data.overnight_stay !== "" && data.overnight_stay !== null ? (<div className="itinerary-col1">
                 <h5>{data.overnight_stay}</h5>
              </div>):null}

              {data.travel_duration !== "" && data.travel_duration !== null ? (<div className="itinerary-col1 dur2">
                 <h5>{data.travel_duration}</h5>
                 <h6>Travel Duration</h6>
              </div>):null}


            </div>
          </div>
        </div>;
    }
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(ItineraryBlock);
