import React, {  Component} from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

const $ = window.jQuery;

const currencyFormat = (num) => {  
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = dispatch => ({

});

class CheckoutItem extends Component {

  constructor(props) {
      super(props);

      this.state = {
          moreInfo:this.props.cartItemIndex === 0 ? true : false
      }



  }

  getDuration(measure){
    switch(measure){
      case 1 :{ return "Days" }
      case 2 :{ return "Hours" }
      case 3 :{ return "Minutes" }
    }
  }

  componentDidMount=()=>{
      $('select').formSelect();
  }

  toggleInfPanel = () =>{
      this.setState({
        moreInfo:!this.state.moreInfo
      });
  }

  formatDate = (date) => {
  var monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];

  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();

  return day + ' ' + monthNames[monthIndex] + ' ' + year;
}

    render() {

        const { data } = this.props;
        const { cartItemIndex } = this.props;

        return <div className="cart-item-box">

          <div className="cart-date">Tour #{cartItemIndex+1}</div>
          <div className="cart-item-info">
            <div className="cart-item-thumb-wrap">
              <div className="cart-item-thumb">
                <img src={data.activity.activityObject.thumb_image} width="115" height="auto" alt="" />
              </div>
            </div>
            <div className="cart-item-details">
              <h3>{data.activity.name}</h3>
              <div className="date-info">{this.formatDate(new Date(data.from_date))}</div>
              <div className="no-cilents">{data.adults} Adults {data.children !== null ? ( data.children+' Children' ):null} {data.children !== null ? ( data.infants+' Infants' ):null}</div>
              <span className="tour-location">{data.activity.activityObject.location !== null ? data.activity.activityObject.location.name : null }</span>
            </div>

            <div className="pref-pricewrap">
            <div className="tour-prices"> 
            <span className="newsprice">
              <p>${currencyFormat(data.amount)}</p>
              </span>
            </div></div>

          </div>
          <div className={"chk-loadmore "+(this.state.moreInfo ? 'acts':'')}>
              <a className="chk-more" onClick={()=>this.toggleInfPanel()}>Lead Traveler Details</a>
              <span className="chk-more-info">Information Required</span>
          </div>
          <div className={"details-form-wrap "+(this.state.moreInfo ? 'acts':'')}>
             <div className="row">
                  <form className="col s12">
                    <div className="row">
                    <div class="input-field col l2 md2 s12">
                      <label>Title</label>
                        <select onChange={(e)=>{ this.props.onTravelerChange(e,'title',this.props.cartItemIndex,0)}}>
                          <option value="Mr">Mr</option>
                          <option value="Mrs">Mrs</option>
                          <option value="Miss">Miss</option>
                        </select>
                     </div>
                      <div className="input-field col l5 m5 s12">
                        <label>First Name<span className="required">*</span></label>
                        <input id="first_name" type="text" className="validate" onChange={(e)=>{ this.props.onTravelerChange(e,'firstname',this.props.cartItemIndex,0)}}/>
                        {this.props.errorFn !== null ? <span style={{ color:"red"}}> {this.props.errorFn} </span> : ''}
                      </div>
                      <div className="input-field col l5 m5 s12">
                        <label>Last Name<span className="required">*</span></label>
                        <input id="last_name" type="text" className="validate" onChange={(e)=>{ this.props.onTravelerChange(e,'lastname',this.props.cartItemIndex,0)}}/>
                        {this.props.errorLn !== null ? <span style={{ color:"red"}}> {this.props.errorLn} </span> : ''}
                      </div>
                    </div>

                    <div className="row">
                      <div className="input-field col m7 s12">
                        <label>Contact Number<span className="required">*</span></label>
                        <input id="contact_number" type="text" className="validate" onChange={(e)=>{ this.props.onTravelerChange(e,'phone',this.props.cartItemIndex,0)}}/>
                        {this.props.errorphone !== null ? <span style={{ color:"red"}}> {this.props.errorphone} </span> : ''}
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-field col m7 s12">
                        <label>Email<span className="required">*</span></label>
                        <input id="contact_number" type="text" className="validate" onChange={(e)=>{ this.props.onTravelerChange(e,'email',this.props.cartItemIndex,0)}}/>
                        {this.props.errorEmail !== null ? <span style={{ color:"red"}}> {this.props.errorEmail} </span> : ''}
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-field col s12">
                        <label>Pickup Location<span className="required">*</span></label>
                        <textarea id="textarea1" className="materialize-textarea" placeholder="Text are to describe where to pick up.. Example Kandalama Hotel Lobby" onChange={(e)=>{this.props.onPickupChnage(e,'pickup_location',this.props.cartItemIndex)}} ></textarea>
                        {this.props.errorPickup !== null ? <span style={{ color:"red"}}> {this.props.errorPickup} </span> : ''}
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-field col s12 notes">
                        <label for="email">Special Note</label>
                        <textarea id="textarea1" className="materialize-textarea" onChange={(e)=>{this.props.onPickupChnage(e,'remarks',this.props.cartItemIndex)}}></textarea>

                      </div>
                    </div>

                  </form>
                </div>
          </div>
          
        </div>;
    }
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(CheckoutItem);
