import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'
import Home from '../src/Pages/Home';
import TailorMade from '../src/Pages/TailorMade';
import SearchResult from '../src/Pages/SearchResult';
import Cart from '../src/Pages/Cart';
import TourDetail from '../src/Pages/TourDetail';
import Checkout from '../src/Pages/Checkout';
import ForgotPassword from '../src/Pages/ForgotPassword';
import AboutUs from '../src/Pages/common/Aboutus';
import PaymentConfirm from "../src/Pages/PaymentConfirm";

export default ()=>(
    <BrowserRouter basename="/">
    <div>
        <Route path="/" exact render={(props)=><Home cid={37}  {...props} />}   />
        <Route path="/AboutUs" exact render={(props)=><AboutUs cid={18}  {...props} />}  />
        <Route path="/Privacy" exact render={(props)=><AboutUs cid={20}  {...props} />}  />
        <Route path="/Terms" exact render={(props)=><AboutUs cid={19}  {...props} />}  />
        <Route path="/Cookies" exact render={(props)=><AboutUs cid={21}  {...props} />}  />
        <Route path="/tour/show/:tid" exact component={TourDetail}  />
        <Route path="/SearchResult" exact component={SearchResult}  />
        <Route path="/Cart" exact component={Cart}  />
        <Route path="/BespokeTours" exact component={TailorMade}  />
        <Route path="/Checkout" exact component={Checkout}  />
        <Route path="/ForgotPassword" exact component={ForgotPassword}  />
        <Route path="/confirmed" exact  render={(props)=><PaymentConfirm cid={38}  {...props} />}  />

        <Route path="/day-tours/:seourl/details/:tid" component={TourDetail}  />
        <Route path="/round-tours/:seourl/details/:tid" component={TourDetail}  />
        <Route path="/overnight-tours/:seourl/details/:tid" component={TourDetail}  />
        <Route path="/things-to-do/:seourl/details/:tid" component={TourDetail}  />
        <Route path="/transfers/:seourl/details/:tid" component={TourDetail}  />
    </div>
     </BrowserRouter>
);
