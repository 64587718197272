import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Headerinner from '../Pages/common/HeaderInner';
import Footer from '../Pages/common/Footer';
import CartItem from '../Pages/common/CartItem';
import {Link} from 'react-router-dom'
import {Helmet} from "react-helmet";


import { CartRemoveItem,GetCartTotal } from '../Actions/CartActions';


const currencyFormat = (num) => {  
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const mapStateToProps = (state) => {
    return {
      CartItems: state.Cart.items,
      CartTotal:GetCartTotal(state.Cart.items),
      CartTotalFormatted:state.Cart.totalFormatted,
    }
}

const mapDispatchToProps = dispatch => ({
      CartRemoveItem:(ItemID)=>dispatch(CartRemoveItem(ItemID))
});





 

class Cart extends Component {

    owlRef = React.createRef();
    featuredItemsRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            value: 0
        }

    }


    handleChange = (event) => {
        this.setState({
            value: event.target.value
        })
    }


    formatDate = (date) => {
    var monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
    ];

    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();

    return day + ' ' + monthNames[monthIndex] + ' ' + year;
  }

    render() {

        const { CartTotal } = this.props;

        return <Fragment>
			
        <Helmet>
          <meta charSet="utf-8" />
          <title>Cart</title>
          {/* <meta name="description" content="description" />
          <meta property="og:title" content="og:title" />
          <meta property="og:type" content="article" />
          <meta property="og:url" content="og:url" />
          <meta property="og:image" content="og:image" /> */}
        </Helmet>
           <Headerinner></Headerinner>
           <div className="contentwrap">
    <div className="title-wrap crt">
      <div className="container">
        <div className="row">
          <div className="col l3 m12 s12">
            <div className="breadscrumb-wrap">
               <div className="nav-wrapper">
                <div className="row">
                  <div className="col s12">
                    <a href="/" className="breadcrumb">Home</a>
                    <a href="cart" className="breadcrumb">cart</a>

                  </div>
                </div>
                </div>
            </div>
            <div className="m-title"><h1>Cart</h1></div>
          </div>
          <div className="col l9 m12 s12">


          </div>
        </div>
      </div>
    </div>


    <div className="cart-wrap">
      <div className="container">
        <div className="row">
          <div className="col l8 m12 s12" >
              { this.props.CartItems.map((item,index)=>{
										return <CartItem onRemoveItem={this.props.CartRemoveItem} data={item} itemIndex={index} component={Link} to={"/TourDetail"} />
								})}
              {this.props.CartItems.length!==0?
              <Link to={'/checkout'}  className="waves-effect waves-light btn pay">Continue & Pay</Link>:null}

          </div>
          <div className="col l4 m12 s12 cartskick">
            <div className="cart-price-info-wrap">
              <div className="cart-info-section">
                <div className="cart-total-wrap">
                  <h4>Cart Total </h4>
                  <div className="ct-price"> ${currencyFormat(CartTotal)}</div>
                </div>
                <div className="total-price">
                  <h5>Total price</h5>
                  <h6>${currencyFormat(CartTotal)}</h6>
                </div>
                { this.props.CartItems.length > 1 ? (
                  <div className="tour-routewrap">
                    <div className="t-start">
                      <h5>Starts</h5>
                      <h6>{this.props.CartItems[0].activity.name}</h6>
                       <span className="start-cal">{this.props.CartItems[0].from_date !== "" ? this.formatDate(new Date(this.props.CartItems[0].from_date)):null}</span>
                       <span className="tour-location">{this.props.CartItems[0].activity.activityObject.location !== null ? this.props.CartItems[0].activity.activityObject.location.name : null }</span>
                    </div>

                    <div className="t-start">
                      <h5>ends</h5> 
                      <h6>{this.props.CartItems[this.props.CartItems.length-1].activity.name}</h6>
                       <span className="start-cal">{this.props.CartItems.from_date !== undefined ? this.formatDate(new Date(this.props.CartItems[this.props.CartItems.length-1].from_date)):null}</span>
                       <span className="tour-location">{this.props.CartItems[this.props.CartItems.length-1].activity.activityObject.location !== null ? this.props.CartItems[this.props.CartItems.length-1].activity.activityObject.location.name : null }</span>
                    </div>
                  </div>
                ):null}


              </div>

               <div className="cart-info-section">

                <div className="cart-infos">
                  <h5>Lowest price Guarantee</h5>
                  <p>find it cheaper? We'll refund the difference</p>
                </div>

                <div className="cart-infos">
                  <h5>24/7 Global Support</h5>
                  <p>Get the answers you need, when you need them</p>
                </div>
                <div className="question-wrap">
                    <h3>For Questions call us</h3>
                    <a href="tel:+94 112 579 679">+94 112 579 679</a>
                </div>

              </div>

            </div>

          </div>
        </div>
      </div>


    </div>


  </div>
            <Footer/>
        </Fragment>
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Cart);
