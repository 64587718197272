export default function reducer(state={
    user:{
                id: 0,
                avatar:"",
                email:"",
                first_name:"",
                last_name:"",
                phone_number:"",

          },
    authToken : null,
    loggedIn:false,
    list:[],
    selectedUser:null,
    showLoginModal:false,
    selectedDate: new Date()   
},action){
switch(action.type){
    case "STORE_TOKEN":{
        return{...state,authToken:action.token}
    }
    case "STORE_USER":{
        return{...state,user:action.user,loggedIn:true,showLoginModal:false}
    }
    case "STORE_USERS":{
        return{...state,list:action.users.data}
    }
    case "USER_SELECTED":{
        return{...state,selectedUser:action.user}
    }
    case "USER_LOGOUT":{
        return{...state,authToken:null,loggedIn:false}
    }
    case "SHOW_LOGIN":{
        return{...state,showLoginModal:true}
    }
    case "HIDE_LOGIN":{
        return{...state,showLoginModal:false}
    }
    case "USER_LOGOUT":{
        return{...state,authToken:null,loggedIn:false}
    }
    case "UPDATE_SELECTED_DATE":{
        return{...state,selectedDate:action.date}
    }    
}
return state
}