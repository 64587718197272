import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
const $ = window.jQuery;



const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = dispatch => ({

});






class TourDetailsHead extends Component {

    owlRef = React.createRef();
    featuredItemsRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            value: 0
        }

    }


    handleChange = (event) => {
        this.setState({
            value: event.target.value
        })
    }

	componentDidMount = () =>{
        
        const el = findDOMNode(this.refs.tsb); 
      $(window).scroll(function() {
                  if ($(this).scrollTop() > 550) {
                      $('.tourtabwrap').addClass('fix');
                  }
                  if ($(this).scrollTop() < 554) {
                      $('.tourtabwrap').removeClass('fix');
                  } else {}
              });
        
              $('.hamburger').click(function() {
                $(this).toggleClass('is-active');    
            });
                    
            $('.overlays').click(function() {     
                $('.menu').removeClass('open-menu'); 
                $('body').removeClass('overlay');
                $('body').removeClass('menuon');
                $('.hamburger').removeClass('is-active');
            });
          
    }


    render() {
        return  <Fragment>
                        <div>
                                <ul className="section table-of-contents" style={{paddingTop:30,width:600}}>
                                  <li><a href="#overview">overview</a></li>
                                  <li><a href="#itinerary">itinerary</a></li>
                                  <li><a href="#inclusions">Inclusions</a></li>
                                  <li><a href="#exclusions">Exclusions</a></li>
                                  <li><a href="#conditions">Conditions</a></li>
                                </ul>
                        </div>
                </Fragment>
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(TourDetailsHead);