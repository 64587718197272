import { API_URL} from './config';
import store from '../Store';
export function startLoading(){ 
    return{type:'LOADIND_START'};
}

export function endLoading(){ 
    return{type:'LOADIND_END'};
}

export function SnackBar(show,message,varient){ 
    return {
                type:'SHOW_ALERT',
                show : show,
                message : message,
                varient:varient,                
           } ;

}

export function ConfimEmail(show,data,action){ 
    return {
                type:'CONFIRM_EMAIL',
                show : show,
                data : data,
                onConfirm:action                                
           } ;

}

export function showInquire(show) {
    return {
        type: 'SHOW_INQUIRE',
        show: show,
       
    }
}

export function handleHTTPError(response){
    if (!response.ok) {       
        throw Error(response.statusText);
    }
    return response;    
}


 export function fetchInquare(name,email,phone,message){

    return function(dispatch) {
      
          fetch(API_URL+'/api/inquiry/',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
        
            },
            body:JSON.stringify({name:name,email:email,phone:phone,message:message}),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
            
                  dispatch(SnackBar(true,'Message added','success'));                                    
              });
         }).catch((error)=>{              
           dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not sent request','error'));
        });
     }   

 }




export function storeInquiry(inq){
    return{type:'STORE_INQUIRE',inquaries:inq};
}