import React, {Component, Fragment} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {findDOMNode} from 'react-dom';
import {GetCostEstimation,LockEstimateUpdate,GetAvailability} from '../../Actions/ActivityActions';
import {CartAddItem} from '../../Actions/CartActions';
import {storeTourDetails, lockTourDetails} from '../../Actions/TourDetailsActionS';
import PriceLocation from './PricingWidgetLoc'

const $ = window.jQuery;


const currencyFormat = (num) => {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const mapStateToProps = (state) => {
  return {
    tour: state.Activities.activeTour,
    estimatedFormatted: state.Activities.activeEstimationFormatted,
    estimated: state.Activities.activeEstimation,
    tourStore: state.Tour.activeTour,
    pickupEstimates:state.Activities.pickupEstimates,
    estimateStatue:state.Activities.estimateUpdate,
    selectedDate:state.Auth.selectedDate
  }
}

const mapDispatchToProps = dispatch => ({

  GetCostEstimation: (date, adults, children, infants, pickupLocation, activityId) => dispatch(GetCostEstimation(date, adults, children, infants, pickupLocation, activityId)),
  CartAddItem: (data) => dispatch(CartAddItem(data)),
  storeTourDetails: (data) => dispatch(storeTourDetails(data)),
  lockTourDetails: () => dispatch(lockTourDetails()),
  LockEstimateUpdate: () => dispatch(LockEstimateUpdate()),
  GetAvailability: (tid,date) => dispatch(GetAvailability(tid,date)),
});

class TourBook extends Component {

  owlRef = React.createRef();
  featuredItemsRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      
      value: 0,
      updatenumInfants: 0,
      updatenumChidren: 0,
      updatenumAdults: 0,
      date: null,
      pickupDate: new Date(this.props.selectedDate),
      pickupLocation: null,
      numAdults: 2,
      numChidren: 0,
      numInfants: 0,
      amount: null,
      travelers: "02 Adults",
      tempLoc: "",
      errorMessage: "",
      showCalander: false,
      updateTotal:false,
      amntFormatted:0,
      amountAbsolute:0,
      pickupTime:null

    }
   
  }

  static getDerivedStateFromProps(props, state) {
    if (props.tourStore.updated) {
      props.lockTourDetails();
      return {from_date: props.tourStore.from_date, location_id: props.tourStore.location_id, adults: props.tourStore.adults, children: props.tourStore.children, infants: props.tourStore.infants}
    }
  }

  handleChange = (event, field) => {
    this.setState({
      [field]: event.target.value
    }, () => {});
  }

  handleDateChange = () => {
    this.setState({pickupDate: this.state.date},()=> this.updateCosting())
  }
  handleAvailb = () => {
  }

  componentDidUpdate = (prevProps) => {

    console.log(this.props.tData.min_date, "sajith date format")

    if(this.state.date===null&&this.props.tData.min_date!==undefined)
      { var minDate = new Date(this.props.tData.min_date)
        this.setState({
          date:minDate,
          pickupDate:minDate
        })
      }

    if(this.state.pickupLocation===null)
      {
        this.props.GetCostEstimation(this.formatDate(this.state.pickupDate), this.state.numAdults, this.state.numChidren, this.state.numInfants, this.props.data.loc_id, this.props.tData.id);
      }
    if (this.state.id === 0 && this.state.tour === undefined) {

      this.setState({tour: this.props.data, id: this.props.data.id})

    }

  if(this.props.data[0]!==undefined&&(this.state.pickupLocation===null||this.state.pickupLocation===""))
    {
      if(this.props.data[0].loc_id!==this.state.pickupLocation)
        { 
          this.setState({
            pickupLocation:this.props.data[0].loc_id
          })
        }
    } 

    
    if(this.props.data.length!==0){
    if((this.props.data[0].loc_id===this.state.pickupLocation)&&this.state.errorMessage==="Please select your pick-up location")
    {
      this.updateCosting()
    }}


    if((this.state.amntFormatted === 0 && this.props.estimatedFormatted.length>0 ) || (this.props.estimateStatue===true&&this.props.estimatedFormatted.length>0))
      {
        this.props.LockEstimateUpdate()
        this.setState({
          amntFormatted:this.props.estimatedFormatted[0].amount_formatted,
          amountAbsolute:this.props.estimatedFormatted[0].amount,
          pickupTime:this.props.estimatedFormatted[0].pickup_time
        })
      }
 

  }

  handleAddItem = (BookNow) => {

    if(this.state.pickupLocation === null||this.props.tourAvlb!==1){
        return false;
    }


    this.props.storeTourDetails({
      from_date: this.state.pickupLocation.pickup_date,
      location_id: this.state.pickupLocation.location_id,
      adults: this.state.pickupLocation.adult,
      children: this.state.pickupLocation.children,
      infants: this.state.pickupLocation.infants
    })

    this.props.CartAddItem({
      user_id: 0,
      from_date: this.state.date,
      amount: this.state.amountAbsolute,
      amntFormatted: this.state.amntFormatted,
      pickup_location_id:this.state.pickupLocation,
      location_id: this.state.pickupLocation,
      pickuptime: this.state.pickupTime,
      adults: this.state.numAdults,
      children: this.state.numChidren,
      infants: this.state.numInfants,
      activeEstimationFormatted:this.props.estimatedFormatted,
      activeEstimation:this.props.estimated,
      activity: {
        id: this.props.tData.id,
        name: this.props.tData.title,
        image: this.props.tData.thumb_image,
        duration: this.props.tData.duration,
        duration_value: this.props.tData.duration_value,
        activityObject: this.props.tData
      },
      bookNow: BookNow

    })
  }





  handleDate = (val) => { 
    var selDate=new Date(val)
    var minDate=new Date(this.props.tData.min_date)

    var dateSel=selDate.getDate()+'/'+ ('0'+(selDate.getMonth() + 1)) +'/'+selDate.getFullYear()
    var mDate=minDate.getDate()+'/'+ ('0'+(minDate.getMonth() + 1)) +'/'+minDate.getFullYear()

    if(new Date(this.props.tData.min_date)> new Date(val) && mDate!==dateSel)
      {return false}

    this.setState({date: new Date(val), showCalander: false, pickupDate: new Date(val)},()=> {this.updateCosting()})

  }


  updateCosting = () => {
    
    if (this.state.pickupLocation === "" || this.state.pickupLocation === null || this.state.pickupLocation === undefined) {
      this.setState({errorMessage: "Please select your pick-up location"});
      return false;

    } else {
      this.setState({errorMessage: ""});
    }

    if (this.state.numAdults !== 0 && this.state.numAdults !== "" && this.state.numAdults !== null && this.state.numAdults !== undefined) {

      this.setState({errorMessage: ""});
    } else {

      this.setState({errorMessage: "There should be at least one adult"});
      return false;
    }

    if (this.state.numAdults !== 0 && this.state.numAdults !== "" && this.state.numAdults !== null && this.state.numAdults !== undefined) {
      this.setState({errorMessage: ""});

    } else {
      this.setState({errorMessage: "There should be at least one adult"});
      return false;
    }

    this.setState({errorMessage: ""});
    this.props.GetCostEstimation(this.formatDate(this.state.pickupDate), this.state.numAdults, this.state.numChidren, this.state.numInfants, this.state.pickupLocation, this.props.tData.id);
  
  if(this.props.estimatedFormatted.length > 0){
    this.props.GetAvailability(this.props.tData.id,this.formatDate(this.state.pickupDate))
  }
  }

  componentDidMount = () => {

    const el = findDOMNode(this.refs.tsb);
    $(window).scroll(function() {
      if ($(this).scrollTop() > 550) {
        $('.tourtabwrap').addClass('fix');
      }
      if ($(this).scrollTop() < 554) {
        $('.tourtabwrap').removeClass('fix');
      } else {}
    });

    $(function() {
      $("#hmlocation").on("click", function(e) {
        $('.search-dropdown').toggleClass('opn');
        e.stopPropagation();
      });

      $(document).on('focus', '.focus_field', function(e) {
        resetBox();
        var target = $(this).data('focus');
        $("." + target).addClass("opn");
      });

      $(document).on('click', '.focus_field', function(e) {
        resetBox();
        var target = $(this).data('focus');
        $("." + target).addClass("opn");
      });

      function resetBox() {

        $('.hide_block').removeClass('opn');

        if (!$('.fulloverlay').hasClass('opn')) {
          $('.fulloverlay').addClass('open');
        }

        if (!$('.tour-detail-box').hasClass('tdpop')) {
          $('.tour-detail-box').addClass('tdpop');
        }
      }

    });
    $(function() {
      $("#datepicker").datepicker();
    });

    var _ = this;
    var width = $(window).width();
    if (width < 480) {
      $("#tdatepicker").datepicker({numberOfMonths: 1, showButtonPanel: true});

    } else {
      $("#tdatepicker").datepicker({
        numberOfMonths: 2,
        showButtonPanel: true,
        onSelect: (v) => {
          _.handleDate(v);
        }
      });

    }

    $('.fulloverlay').click(function() {
      $('.fulloverlay').removeClass('open');
      $('.tour-detail-box').removeClass('tdpop');

      $('.travel-people-wrap').removeClass('opn');
      $('.travel-pickups').removeClass('opn');

    });




  }

  handlePlusInfant = () => {
    this.setState({
      numInfants: this.state.numInfants + 1
    })
  }

  handleMinInfant = () => {
    if (this.state.numInfants !== 0) {
      this.setState({
        numInfants: this.state.numInfants - 1
      })
    }
  }

  handlePlusChild = () => {
    this.setState({
      numChidren: this.state.numChidren + 1
    })
  }

  handleMinChild = () => {
    if (this.state.numChidren !== 0) {
      this.setState({
        numChidren: this.state.numChidren - 1
      })
    }
  }

  handlePlusAdult = () => {
    this.setState({
      numAdults: this.state.numAdults + 1
    })
  }

  handleMinAdult = () => {
    if (this.state.numAdults !== 1) {
      this.setState({
        numAdults: this.state.numAdults - 1
      })
    }
  }
  handleUpTra = () => {
    var total=this.state.numInfants+this.state.numChidren+this.state.numAdults
    if(total<10)
      {
        total="0"+total
      }
    this.setState({
      travelers:  this.state.numChidren!==0||this.state.numInfants!==0 ? total+" Travelers":this.state.numAdults===1?"0"+this.state.numAdults+" Adult":total+" Adults"
    })
    if (this.state.pickupDate !== null && this.state.pickupLocation !== null && this.state.updatenumAdults !== null) {
      this.updateCosting() 
    }

  }

  handleChangeLoc = (location) => {
    
    if(location !== null) {
      this.setState({
        pickupLocation:location.location.id,
        amntFormatted:location.amount_formatted,
        pickupTime:location.pickup_time,
        amountAbsolute:location.amount,
      })
    }
  }

  formatDate = (date) => {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();

    return day + ' ' + monthNames[monthIndex] + ' ' + year;
  }

  render() {
    
    return <Fragment>
      <div className="col l5 m12 s12">

        <div className="tour-detail-box">
        { this.props.estimatedFormatted.length > 0 && this.props.estimatedFormatted[0].deal !== null ? 
                   <div class="t-offer">{this.props.estimatedFormatted[0].deal}% Off</div>:''
                }
         
          {
            this.state.pickupLocation !== null 
              ? (<div className="tour-detail-pricebox">
                <div className="pricefrom">
                  { this.props.estimatedFormatted.length > 0 && this.props.estimatedFormatted[0].deal !== null ? 
                 <div> Estimated<br />
                   <span>{this.props.estimatedFormatted[0].total_amount_formatted}</span></div>:''
                  }
                
                </div>
                <div className="t-price">
                { this.props.estimatedFormatted!==""&&this.props.estimatedFormatted!==undefined&&this.props.estimatedFormatted.length!==undefined?<h3> ${this.state.pickupLocation !== undefined ? currencyFormat(this.state.amountAbsolute) : null}</h3>:null}
                  </div>
                  <div class="no-of-people">
                  <p>Total for<br/>{this.state.travelers}</p>
                </div>
              </div>)
              : null
          }

          <div className="priceform-wrap">
        <h3>{this.props.tourAvlb===1?"Your date is available":"Select Date & Travelers"}</h3>
            {
              this.state.errorMessage !== ""
                ? (<span className="validationError">{this.state.errorMessage}</span>)
                : null
            }

            <div className="travel-form">
              <div className="row">

                <div className="input-field col m6 s12 tour-d">
                  {console.log(this.state.date,"sasascscs",this.props.tData.min_date)}
                  <input type="text"  onFocus={() => this.setState({showCalander: true})} className="focus_field travel_date" value={this.formatDate(this.state.pickupDate)} />
                </div>

                <div className="input-field col m6 s12 tr-no-people">
                  <input type="text" className="focus_field travel_people" data-focus="travel-people-wrap" value={this.state.travelers} onChange={() => this.handleAvailb()}/>
                </div>

                <div className={" travel-cal2 " + (
                    this.state.showCalander === true
                    ? 'opn'
                    : '')}>
                  <div id="tdatepicker" onClick={() => this.handleAvailb()}></div>
                  <a onClick={() => this.handleDateChange()} className="waves-effect waves-light btn updatebtn">update</a>
                </div>

                <div className="hide_block travel-people-wrap">
                  <div className="t-people-row">
                    <div className="people-row">
                      <div className="p-ages">
                        <h5>Adult</h5>
                        Age 12 - 99
                      </div>

                      {/* className="counter-minus" */}
                      <div className="p-counter-wrap">
                        <div className="handle-counter" id="handleCounter">
                          <button onClick={() => this.handleMinAdult()}>-</button>
                          <input type="text" className="counter-text" value={this.state.numAdults}/>
                          <button className="counter-plus" onClick={() => this.handlePlusAdult()}>+</button>
                        </div>
                      </div>
                    </div>

                    <div className="people-row">
                      <div className="p-ages">
                        <h5>Child</h5>
                        Age 2 - 11
                      </div>

                      <div className="p-counter-wrap">
                        <div className="handle-counter" id="handleCounter2">
                          <button onClick={() => this.handleMinChild()}>-</button>
                          <input type="text" className="counter-text" value={this.state.numChidren}/>
                          <button className="counter-plus" onClick={() => this.handlePlusChild()}>+</button>
                        </div>
                      </div>
                    </div>

                    <div className="people-row">
                      <div className="p-ages">
                        <h5>Infant</h5>
                        Age 0 - 1
                      </div>

                      <div className="p-counter-wrap">
                        <div className="handle-counter" id="handleCounter3">
                          <button onClick={() => this.handleMinInfant()}>-</button>
                          <input type="text" value={this.state.numInfants} className="counter-text"/>
                          <button className="counter-plus" onClick={() => this.handlePlusInfant()}>+</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a onClick={() => this.handleUpTra()} className="waves-effect waves-light btn updatebtn">update</a>
                </div>
              </div>  
              <PriceLocation data={this.props.data} loc={this.props.estimatedFormatted} handleChangeLoc={(location) => this.handleChangeLoc(location)}  updateCosting={() =>this.updateCosting()}/>

              <div className="row">
                <div className="input-field col m12 s12">
                  <div className="bknowwrap" style={{
                      width: 463
                    }}>
                    <a style={{ 
                        cursor: 'pointer'
                      }} onClick={() => this.handleAddItem(true)} className="waves-effect waves-light btn pay bknw">Book now</a>
                  </div>
                </div> 
                <div className="input-field col m12 s12 cancelwrap">

                  <h5>Free Cancellation</h5>
                  <p>Up to 24 hours in advance</p>


                </div>

              </div>
            </div>

          </div>
        </div>
      </div>

    </Fragment>
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(TourBook);
