import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import StarRatingComponent from 'react-star-rating-component';


const getFormattedPrice = (price)=>{
    let splittedPrice = price.toString().split('.');

    return <Fragment>{splittedPrice[0]}<sup>.{splittedPrice[1]}</sup></Fragment>
}

const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = dispatch => ({

});

class ListCard extends Component {

  getDuration(measure){
    switch(measure){
      case 1 :{ return "Days" }
      case 2 :{ return "Hours" }
      case 3 :{ return "Minutes" }
    }
  }


    render() {

        const { data } = this.props;

        return <div className="row">
            <div className="col md12 s12">
                <div className="prefered-wrap">
                    <div className="row">
                        <div className="prefered-slider">
                            <div className="col s12 m12 ps-item">
                                <div className="card horizontal">

                                    <div className="card-image">
                                        {data.featured === 1 ? (<span className="t-featured"><i className="sprite sprite-featured-rec"></i></span>):null}
                                        {data.deal_price!==null&&data.price !== null?<span className="s-offer">Special offer</span>:null} 

                                        <a style={{cursor:'pointer'}} onClick={()=>window.location.href="./tour/show/"+this.props.data.id}><img src={data.thumb_image}  /></a>
                                        <span className="more-info"   >{data.categories[0].category_name} { data.categories.length > 1 ? (<em>+{data.categories.length-1} more</em>) : null }</span>
                                    </div>
                                    <div className="card-stacked">
                                        <div className="card-content">
                                            <div className="prefered-tour-title">
                                                <h4><a style={{cursor:'pointer'}} onClick={()=>{ window.location.href="/tour/show/"+data.id }}>{data.title.substr(0, 55)}{data.title.length > 55 ? '...':''}</a></h4>
                                            </div>
                                            <div className="pref-tour-info-wrap">
                                                <div className="tour-info">
                                                    <span className="tour-location">{ data.location !== null ? data.location.name:null}</span>
                                                    { data.free_cancellation === 1 ? (<div className="tour-option"><i className="sprite sprite-tour-checked"></i> Free Cancellation</div>):null }

                                                    <div className="tour-duration"><i className="sprite sprite-tour-duration-icon"></i>{data.duration_value} {this.getDuration(data.duration)}</div>
                                                    <div className="ratewrap">
                                                      <StarRatingComponent
                                                                  name="rate2"
                                                                  editing={false}
                                                                  renderStarIcon={() => <span></span>}
                                                                  starCount={5}
                                                                  value={data.average_rating}
                                                                  renderStarIcon={() => <i style={{ fontSize: 21, fontStyle: 'normal',color:'#f7cf2e' }} > {this.props.data.average_rating === null ? ('☆'):'★' } </i>}
                                                                />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pref-tour-details">
                                                <p>{data.overview !== null ? data.overview.substr(0, 113) : null}...</p>
                                            </div>

                                        </div>
                                        <div className="card-action">
                                            <a href={"/tour/show/" + data.id} className="waves-effect btn viewtour">View Tour </a>
                                            <div className="pref-pricewrap">

                                                <div className="tour-prices">
                                                    {data.deal_price!==null&&data.price !== null?<span className="oldsprice"> ${data.price.selling_price}</span>:null} 
                                                    {data.deal_price !== null ? <span className="newsprice"><span>from</span> ${getFormattedPrice(data.deal_price)}</span>:null}
                                                    {data.deal_price === null &&  data.price !== null ? <span className="newsprice"><span>from</span>${getFormattedPrice(data.price.selling_price)}</span>:null }
                                                    
                                                </div> 
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(ListCard);
