export function storeTourDetails(list) {
    console.log(list)
    return {
        type: 'STORE_TOUR_DETAILS',
        list: list
    }
}

export function lockTourDetails() {
    return {
        type: 'LOCK_STORE_TOUR_DETAILS',
        list:null
    }
}