import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Header from '../Pages/common/Header';
import Footer from './common/Footer';
import dropdown from './img/dropdown-img.png';
import { TopSellingActivities, DealsActivities } from '../Actions/ActivityActions';
import { getSearchSuggestions,FetchSliders,clearSearchSuggestions,FetchDealAndDiscount, storeSelectedDate} from '../Actions/WebsiteActions';
import { fetchLocations } from '../Actions/LocationAction';
import DestinationTile from './common/DestinationTile';
import HomeGridCard from './common/HomeGridCard';
import OwlCarousel from 'react-owl-carousel';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import 'owl.carousel/dist/assets/owl.carousel.css';
import {Helmet} from "react-helmet";
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { GetCMS } from '../Actions/CmsActions';
import { now } from 'moment';
import moment from 'moment';

const $ = window.jQuery;
const today = new Date();
const mapStateToProps = (state) => {
  return {
    Activity: state.Activities.result,
    Website: state.Website.dealsDiscountedTours,
    searchResultsActivities:state.Website.searchResultsActivities,
    sliders:state.Website.sliders,
    topSellingDayTours:state.Website.topSellingDayTours,
    topSellingMultiDayTours:state.Website.topSellingMultiDayTours,
    Locations: state.Locations.locations,
    discount:state.Website.dealAndDiscount,
    selectedDate:state.Auth.selectedDate,
    CMS: state.CMS.activecms
  }
}
const mapDispatchToProps = dispatch => ({
  getSearchSuggestions:(keyword)=>dispatch(getSearchSuggestions(keyword)),
  clearSearchSuggestions:()=>dispatch(clearSearchSuggestions()),
  TopSellingActivities: (limit,typeID,type) => dispatch(TopSellingActivities(limit,typeID,type)),
  DealsActivities: () => dispatch(DealsActivities()),
  FetchSliders:()=>dispatch(FetchSliders()),
  fetchLocations: () => dispatch(fetchLocations()),
  FetchDealAndDiscount: (date)=> dispatch(FetchDealAndDiscount(date)),
  storeSelectedDate:(date)=>dispatch(storeSelectedDate(date)),
  fetchMetaContent: (id) => dispatch(GetCMS(id)),
});


const ComponentA = (props) => (<div className={"search-dropdown "+(props.searchResultsActivities.length > 0 && props.condition ? 'opn':null)} >
   
      { props.searchResultsActivities.length > 0 ? (props.searchResultsActivities.map((item)=>{
          return <div className="search-row" onClick={()=>{ window.location.href="/tour/show/"+item.id}}><a href={"/tour/show/"+item.id } >
                    <div className="s-location-img"><img width={77} height="auto" src={item.thumb_image !== null ? item.thumb_image:dropdown}></img></div>
                      <div className="s-location">
                        <p>{item.title}</p>
                        <span>From ${item.price !== null ? item.price.selling_price : null }</span>
                      </div>
                    
                  </a></div>;
      })):null}
      
</div>);



class Home extends Component {
  tourDate = React.createRef();
  SlickSliderRef = React.createRef();
  owlRef = React.createRef();
  featuredItemsRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      id: 0,
      searchField: "",
      searchOnFocus:false,
      selectedDate:null,
      destiSliderInit:false,
      condition: false
    }


    this.props.fetchLocations();

  }

  componentDidMount = () =>{
    var date = null;
    var today=new Date()
    if (this.state.selectedDate!==null)
      {
        date=this.state.selectedDate.getFullYear()+'-'+ ('0'+(this.state.selectedDate.getMonth() + 1)) +'-'+this.state.selectedDate.getDate()
      }
     if(date!==null)
      {
        today=date
      } 
    this.props.TopSellingActivities(8,1,'topSellingDayTours');
    this.props.TopSellingActivities(8,2,'topSellingMultiDayTours');
    this.props.DealsActivities()
    this.props.FetchSliders()
    this.props.FetchDealAndDiscount(today.getFullYear()+'-'+ ('0'+(today.getMonth() + 1)) +'-'+today.getDate());
    this.props.fetchMetaContent(this.props.cid);

    $(this.tourDate.current).datepicker({

          onSelect:(val)=> {

            if(new Date(val)<new Date())
              { 
                this.setState({
                  selectedDate: new Date()
                },()=>this.props.storeSelectedDate(new Date()));
                return false
              }

            this.setState({
              selectedDate: new Date(val)
            },()=>this.props.storeSelectedDate(new Date(val)));

            
          },
           beforeShow: function(input, inst) {
               $('#ui-datepicker-div').removeClass(function() {
                   return $('input').get(0).id;
               });
               $('#ui-datepicker-div').addClass(this.id);
           }
        });


  }

  componentDidUpdate = () =>{


      if(this.props.Locations.length > 0 && this.state.destiSliderInit === false  ){
          var _this = this;
        $(function() {

            if(_this.SlickSliderRef !== undefined){

              $(_this.SlickSliderRef.current).removeClass("slick-initialized slick-slider");
              $(_this.SlickSliderRef.current).slick({
                      autoplay: false,
                      rows: 2,
                      pauseOnHover: false,
                      infinite: false,
                      slidesToShow: 4,
                      slidesToScroll: 1,
                      responsive: [{
                          breakpoint: 1024,
                          settings: {
                              slidesToShow: 2,
                              slidesToScroll: 1,
                              infinite: true,
                              rows: 1,
                              dots: true
                          }
                      }, {
                          breakpoint: 768,
                          settings: {
                              slidesToShow: 2,
                              rows: 1,
                              slidesToScroll: 1
                          }
                      }, {
                          breakpoint: 480,
                          settings: {
                              slidesToShow: 1,
                              rows: 1,
                              slidesToScroll: 1
                          }
                      }]
                  });
            }


          });



  this.setState({
      destiSliderInit:true
  })

      }
  }

  handleChange = (event,field) => {

		this.setState({
			[field]:event.target.value,
      view:'list',
      condition: field === 'searchField' ? true:false
		},()=>{

			setTimeout(()=>{
        this.props.getSearchSuggestions(this.state.searchField);

      },1000)

		});
	}

handleEnterKey = (e) =>{
  var date = null;
  if (this.state.selectedDate!==null)
    {
      date=this.state.selectedDate.getFullYear()+'-'+ ('0'+(this.state.selectedDate.getMonth() + 1)) +'-'+this.state.selectedDate.getDate()
    }
  // var date = this.state.selectedDate.getFullYear()+'-'+ ('0'+(this.state.selectedDate.getMonth() + 1)) +'-'+this.state.selectedDate.getDate();
  if (e.key === 'Enter') {
    window.location.href = "/SearchResult?key=" +
    this.state.searchField +
    (this.state.selectedDate !== null && this.state.selectedDate !== undefined ? '&pickupDate=' +
      (this.state.selectedDate.getFullYear()) + '-' +( (this.state.selectedDate.getMonth() + 1) < 10 ? ('0'+(this.state.selectedDate.getMonth() + 1)):(this.state.selectedDate.getMonth() + 1)) + '-' +
      (this.state.selectedDate.getDate() < 10 ? ('0'+this.state.selectedDate.getDate()):this.state.selectedDate.getDate()) : "") + (this.state.view !== null && this.state.view !== undefined ? '&view=' +
        (this.state.view) : "")
        e.preventDefault();
        this.props.FetchDealAndDiscount(date);
      }
}

handleTabKey = (event) => {
  if(event.keyCode === 9){
    this.setState({
      condition: !this.state.condition
    })
  }
  
}

  render() {


    return (<Fragment>
      
			
      <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.CMS.seo_title}</title>
						<meta name="description" content={this.props.CMS.seo_description} />
						<meta property="og:title" content={this.props.CMS.seo_title} />
						<meta property="og:type" content="article" />					
						
        </Helmet>
      <Header></Header>
      <div className="banner-wrap"  >
          {this.props.sliders.length > 0 ? (<OwlCarousel
          className="owl-theme"
            loop
            items={1}
            >

            {this.props.sliders.map((slide,index)=>(<div className="item"   style={{backgroundImage:`url(${slide.image})`,backgroundRepeat:'no-repeat',backgroundSize:'cover',height:617}} />)) }

          </OwlCarousel>):null}


        <div className="mainbanner-contents">
          <div className="container">
            <div className="row">
              <div className="col m12">
                <div className="hm-searchwrp">
                  <h2>Discover amazing things to do... </h2>
                  <div className="hm-search-box">
                    <div className="row">
                      <div className="col m6 s12">
                        <div className="input-field col s12">   
                          <label>Where are you Going ? </label>
                          <input type="text" autocomplete="off" className="input-field" id="hmlocation" value={this.state.searchField} 
                            onBlur={()=>{this.setState()}}         
                            onFocus={()=>{this.setState({condition: true})}}
                            onChange={(e)=>{ this.handleChange(e,'searchField');  }}   
                            onKeyPress={(e)=>{this.handleEnterKey(e) }}
                            onKeyDown={this.handleTabKey}
                          />
                        </div>
                      </div>
                      <div className="col m5 s12">  
                        <div style={{ paddingRight: 30 }} className="input-field col s12">
                          <label>Travel Date </label> 
                          <input type="text" autoComplete="off"  className="input-field cal" id="hmdatepicker"  readOnly = {true} value={this.state.selectedDate!==null ? this.state.selectedDate.getFullYear()+'-'+ ((this.state.selectedDate.getMonth()+ 1) < 10 ? "0"+(this.state.selectedDate.getMonth()+ 1):(this.state.selectedDate.getMonth()+ 1)) +'-'+(this.state.selectedDate.getDate() < 10 ? "0"+this.state.selectedDate.getDate():this.state.selectedDate.getDate()):null}   ref={this.tourDate}  onKeyPress={(e)=>{this.handleEnterKey(e) }} minDate="2020-07-01" />
                        </div>
                        <a className="waves-effect waves-light btn" 
                          onClick={() => {
                            window.location.href = "/SearchResult?key=" +
                              this.state.searchField +
                              (this.state.selectedDate !== null && this.state.selectedDate !== undefined ? '&pickupDate=' +
                                (this.state.selectedDate.getFullYear()) + '-' +( (this.state.selectedDate.getMonth() + 1) < 10 ? ('0'+(this.state.selectedDate.getMonth() + 1)):(this.state.selectedDate.getMonth() + 1)) + '-' +
                                (this.state.selectedDate.getDate() < 10 ? ('0'+this.state.selectedDate.getDate()):this.state.selectedDate.getDate()) : "") + (this.state.view !== null && this.state.view !== undefined ? '&view=' +
                                  (this.state.view) : "")
                          }}

                          onKeyPress={(e)=>{ this.handleEnterKey(e)}}
                            >
                          <i className="sprite sprite-search-icon"></i></a>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Search Drop down --> */}
                          <ComponentA searchResultsActivities={this.props.searchResultsActivities} condition={this.state.condition} />
                  {/* <!-- Search Drop down end-->      */}
                </div>
              </div>
            </div>
          </div>

        </div>

        </div>
      <div className="contentwrap">
        <div className="contents">
          <div className="tour-box-wrap">
            <div className="container">
              <div className="row">
                <div className="col m12 s12">
                  <div className="t-box-wrap">
                    <div className="t-box">
                      <div className="tour-box">
                        <a
                          style={{cursor:'pointer'}}
                          onClick={() => {
                            window.location.href = "/SearchResult?tourTypes=1";
                          }}>


                          <div className="t-img"><i className="sprite sprite-day-tour-icon"></i></div>
                          <h4>Day Tours</h4>

                        </a>
                      </div>
                      <div className="tour-box">
                        <a
                          style={{cursor:'pointer'}}
                          onClick={() => {
                            window.location.href = "/SearchResuGalle Full-Day Tourlt?tourTypes=2"
                          }}>


                          <div className="t-img"><i className="sprite sprite-multi-day-icon"></i></div>
                          <h4>Multiday Tours</h4>
                        </a>
                      </div>
                      <div className="tour-box">
                        <a
                          style={{cursor:'pointer'}}
                          onClick={() => {
                            window.location.href = "/SearchResult?tourTypes=3"
                          }}>


                          <div className="t-img"><i className="sprite sprite-over-night-icon"></i></div>
                          <h4>Overnight Experiences</h4>
                        </a>
                      </div>
                      <div className="tour-box">
                        <a
                          style={{cursor:'pointer'}}
                          onClick={() => {
                            window.location.href = "/SearchResult?tourTypes=4"                           }}>

                          <div className="t-img"><i className="sprite sprite-thingsto-do"></i></div>
                          <h4>Things to Do</h4>
                        </a>
                      </div>
                      <div className="tour-box">
                        <a
                          style={{cursor:'pointer'}}
                          onClick={() => {
                            window.location.href = "/SearchResult?tourTypes=5"
                          }}>


                          <div className="t-img"><i className="sprite sprite-car-icon"></i></div>
                          <h4>Transfers</h4>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="optionwrap">
            <div className="container">
              <div className="optionwraprow">
                <div className="col opt">
                  <div className="option">
                    <div className="opt-icon-bg">
                      <i className="sprite sprite-thumb-icon"></i></div>
                    <div > <h5>Value for the Price</h5></div>
                  </div>
                </div>
                <div className="col opt">
                  <div className="option">
                    <div className="opt-icon-bg"><i className="sprite sprite-bike-icon"></i></div>
                    <h5>Hundreds of tours & activities</h5>
                  </div>
                </div>
                <div className="col opt">
                  <div className="option">
                    <div className="opt-icon-bg"><i className="sprite sprite-call-icon"></i></div>
                    <h5>24/7 Support</h5>
                  </div>
                </div>
                <div className="col opt">
                  <div className="option">
                    <div className="opt-icon-bg"><i className="sprite sprite-secure-icon"></i></div>
                    <h5>Secure payments</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hm-destination">
          <div className="container">
            <div className="row">
              <div className="col m12 s12">
                <h2>Top Destinations</h2>
              </div>
            </div>
            <div className="row">
              <div className="col m12 s12">
                <div className="destination-slider-wrap">

                  <div className="destination-slider" ref={this.SlickSliderRef} >


                { this.props.Locations.map((item,index)=>{
                  return <DestinationTile data={item}/>;
                }) }
               </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hm-tour-boxwrap">
          <div className="container">
            <div className="row">
              <div className="col m12 s12" >
                <h3>Most Selling Day Tours</h3>
              </div>
            </div>
            <div className="row">
              <div className="mostselling-slider">
                {this.props.topSellingDayTours !== undefined && this.props.topSellingDayTours.length > 0 ? this.props.topSellingDayTours.map((data, index) => {
                  return <HomeGridCard data={data} />
                }) : null}

              </div>
            </div>
            <div className="row">
              <div className="col m12 s12" >
                <a   onClick={() => {
                    window.location.href = "/SearchResult?tourTypes=1";
                  }} className="waves-effect btn viewmore">more day tours</a>
              </div>
            </div>
          </div>
        </div>
        <div className="banner-cancellation">
          <div className="container">
            <div className="row">
              <div className="col m12 center-align">
                <h5><i className="sprite sprite-time-icon"></i> Free Cancellation</h5>
                <p>Free cancellation before 24 hours to the activity starting time</p>
              </div>
            </div>
          </div>
        </div>

        <div className="hm-tour-boxwrap multi">
          <div className="container">
            <div className="row">
              <div className="col l12 s12" >
                <h3>Most Selling Multi Day Tours</h3>
              </div>
            </div>
            <div className="row">
              <div className="mostselling-slider">
                {this.props.topSellingMultiDayTours !== undefined && this.props.topSellingMultiDayTours.length > 0 ? this.props.topSellingMultiDayTours.map((data, index) => {

                  return <HomeGridCard data={data} />
                }) : null}
              </div>
            </div>
            <div className="row">
              <div className="col m12 s12" >
                <a   onClick={() => {
                    window.location.href = "/SearchResult?tourTypes=2";
                  }} className="waves-effect btn viewmore">more multiday tours</a>
              </div>
            </div>
          </div>
        </div>
        <div className="dealswrap">
          <div className="container">
            <div className="row">
              <div className="col m12 s12">
                <h4>Deals & Discounts</h4>
              </div>
            </div>
            <div className="row">
              <div className="col m12 s12">
                <div className="deals-list">
                  {this.props.discount !== null && this.props.discount.length > 0 ? this.props.discount.map((data)=>{
                    var url = "/SearchResult?deal=" + data.id;
                  return<Fragment>
                      <ul>
                         <li><a href={url}  ><span>${data.discount}% off</span>{data.title}</a></li>
                      </ul>     
                       </Fragment>
                   
                  }): null}
               
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>


      <Footer />









    </Fragment>
    );
  
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Home);
