import React, {Component, Fragment} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import Footer from '../Pages/common/Footer'; 
import Headerinner from '../Pages/common/HeaderInner';
import {GetActivity, SearchActivities, GetCostEstimation} from '../Actions/ActivityActions';

import StarRatingComponent from 'react-star-rating-component';
import TourHead from '../Pages/TourDetailHead';
import TourBook from '../Pages/common/PricingWidget';
import {findDOMNode} from 'react-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {Helmet} from "react-helmet";

import ItineraryBlock from '../Pages/common/ItineraryBlock';

const $ = window.jQuery;

const OwlCarouselDeataileptions = {
  dots: true,
  loop: true,
  nav: true,
  items: 1
};

const mapStateToProps = (state) => {

  return {
    tourAvlb: state.Activities.tourAvailable, 
    activeTour: state.Activities.activeTour, 
    relatedTours: state.Activities.relatedTours, 
    Cart: state.Cart}

}

const mapDispatchToProps = dispatch => ({
  GetActivity: (tid) => dispatch(GetActivity(tid)),
  SearchActivities: (searchQuery, store) => dispatch(SearchActivities(searchQuery, store)),
  GetCostEstimation: (date, adults, children, infants, pickupLocation, activityId) => dispatch(GetCostEstimation(date, adults, children, infants, pickupLocation, activityId))
});

function MainSliderItem(props) {
  return "<div class='item' ><div class='slid-itm' style='background-image:url(" + props.image + ")'></div></div>";
}

function getFeaturedCategories(cats) {
  var mergeCats = [];
  for (var x = 0; x < cats.length; x++) {
    mergeCats.push(cats[x].id);
  }

  return mergeCats.join();

}

function FeaturedCategoryItem(props) {
  var item = "<div class='item'>"
  item += "<img src='" + props.image + "' alt='' class='img-fluid'>";
  item += "</div>";

  return item;
  var item = "<div class='item'>";
  item += "<div class='list-tor'>";
  item += "<img src='" + props.image_name + "' />";
  item += "<div>";
  item += "<h6>" + props.title + "</h6>";
  item += "<p>" + props.description + "</p>";
  item += "<a class='waves-effect waves-light btn' href='/search?category=" + getFeaturedCategories(props.categories) + "' >View More</a>";
  item += "</div>";
  item += "</div>";
  item += "</div>";

  return item;
}

class DayTourDetails extends Component {

  owlRef = React.createRef();
  featuredItemsRef = React.createRef();

  constructor(props) {
    super(props);
    var {
      tid
    } = this.props.match.params;

    if (tid !== undefined && tid !== null) {
      this.props.GetActivity(tid);

      let date = new Date();      
    }
    this.state = {
      value: 0,
      num: 0,
      relatedLoaded: false,
      carouselMounted: false,
      slidesLoaded: false,
      catsLoaded: false,
      tourSlideMOunted: false
    }

  }

  handleChange = (event) => {
    this.setState({value: event.target.value})
  }

  componentDidUpdate = () => { 
    if (this.props.Cart.bookNow === true && this.props.Cart.items.length > 0) {
        setTimeout(()=>{
          window.location.href = '/checkout';
        },1000);
    }
  }

  componentDidMount = () => {
    const el = findDOMNode(this.refs.tsb);

    $('.hamburger').click(function() {
      $(this).toggleClass('is-active');
    });

    $('.overlays').click(function() {
      $('.menu').removeClass('open-menu');
      $('body').removeClass('overlay');
      $('body').removeClass('menuon');
      $('.hamburger').removeClass('is-active');
    });

    this.setState({carouselMounted: true})
  }

  getTripType(type) {
    switch (type) {
      case 1:
        {
          return "Day Tours"
        }
      case 2:
        {
          return "Multiday Tours"
        }
      case 3:
        {
          return "Overnight Experiences"
        }
      case 4:
        {
          return "Things to Do"
        }
      case 5:
        {
          return "Transfers"
        }
    }
  }

  getDuration(measure) {
    switch (measure) {
      case 1:
        {
          return "Days"
        }
      case 2:
        {
          return "Hours"
        }
      case 3:
        {
          return "Minutes"
        }
    }
  }

  getConfirmation(status) {
    switch (status) {
      case 1:
        {
          return "Instant"
        }
      case 2:
        {
          return "On approval"
        }
    }
  }

  render() {
    return (<Fragment>
			
      <Helmet>
        <meta charSet="utf-8" />
          <title>{this.props.activeTour.seo_title}</title>
						<meta name="description" content={this.props.activeTour.seo_description} />
						<meta property="og:title" content={this.props.activeTour.seo_title} />
						<meta property="og:type" content="article" />
						<meta property="og:url" content={this.props.activeTour.seo_url} />
						<meta property="og:image" content={this.props.activeTour.image} />
      </Helmet>
      <Headerinner></Headerinner>
      <div>
        <div className="fulloverlay"></div>

        <div className="contentwrap dt-bg">
          <div className="title-wrap crt t-details">
            <div className="container">
              <div className="row">
                <div className="breadscrumb-wrap">
                  <div className="nav-wrapper">
                    <div className="row">
                      <div className="col s12">
                        <a href="/" style={{
                            backgroundColor: "white",
                            paddingTop: 25
                          }} className="breadcrumb">Home</a>
                        <a href={"/SearchResult?tourTypes=" + this.props.activeTour.tour_type} style={{
                            backgroundColor: "white",
                            paddingTop: 25
                          }} className="breadcrumb">Search Result</a>
                        <a href={"/tour/show/" + this.props.activeTour.id} style={{
                            backgroundColor: "white",
                            paddingTop: 25
                          }} className="breadcrumb">{this.props.activeTour.title}</a>
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div className="col l12 m12 s12">
                  <div className="breadscrumb-wrap">
                    <div className="nav-wrapper"></div>
                  </div>
                  <div className="m-title">
                    <h1>{this.props.activeTour.title}</h1>
                  </div>
                  <div className="t-ratings">
                    <div className="ratewrap">
                      <StarRatingComponent name="rate2" editing={false} starCount={5} value={this.props.activeTour.average_rating} renderStarIcon={() => <i style={{
                            fontSize: 25,
                            fontStyle: 'normal',
                            color: '#d6a230'
                          }}>{
                            this.props.activeTour.average_rating === null
                              ? ('☆')
                              : '★'
                          }</i>}/>
                    </div>
                    <span>
                      <p >No reviews yet</p>
                    </span>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="day-tour-wrap">
            <div className="container">
              <div className="row">
                <div className="col l7 m12 s12">
                  {
                    this.props.activeTour.images !== undefined && this.props.activeTour.images.length > 0
                      ? (<OwlCarousel className="owl-carousel owl-theme tourslider" id="tour-gallery" margin={10} items={1} dots="true" nav="true" loop="true">
                        {
                          this.props.activeTour.images !== undefined && this.props.activeTour.images.length > 0
                            ? this.props.activeTour.images.map((data, index) => {
                              return <div className="item"><img src={data.image} alt="" className="img-fluid"/></div>;
                            })
                            : null
                        }
                      </OwlCarousel>)
                      : null
                  }

                </div> 

                <TourBook tData={this.props.activeTour} data={this.props.activeTour.available_locations} tourAvlb={this.props.tourAvlb}/> 

              </div>
            </div>
          </div>
          <div className="tour-full-details">
            <div className="container">
              <div className="row">
                <div className="col l8 m12 s12">
                  <div className="tourtabwrap">
                    <div className="row">
                      <div className="col l12 m12 s12 ">
                        <div className="row">
                          <div className="col m12 l12 s12">
                            <TourHead/>
                          </div>
                          <div className="col m12 l12 s12">
                            <div className="tourtab-wrap-content">
                              <div id="overview" className="section scrollspy sec-overview">
                                <div className="t-tabbox">
                                  <h3>Overview</h3>
                                  <p >
                                    {this.props.activeTour.overview}
                                  </p>

                                </div>

                              </div>
                              <div id="itinerary" className="section scrollspy sec-itinerary">
                                <div className="t-list"> 
                                  <h3>Itinerary</h3>
                                  {
                                    this.props.activeTour.itinery.map((data, index) => {
                                      return <ItineraryBlock tourType={this.props.activeTour.tour_type} data={data} itemItem={index}/>;
                                    })
                                  }

                                </div>

                              </div>
                              <div className="feature-lists">
                                <div id="inclusions" className="section scrollspy sec-inclusions">
                                  <div className="t-list">
                                    <h3>Includes</h3>
                                    <ul>
                                      {
                                        this.props.activeTour.inclusion !== undefined && this.props.activeTour.inclusion !== null
                                          ? this.props.activeTour.inclusion.map((data) => {
                                            return <li>
                                              {data}
                                            </li>
                                          })
                                          : null
                                      }
                                    </ul>
                                  </div>
                                </div>

                                <div id="exclusions" className="section scrollspy sec-exclusions">
                                  <div className="t-list">
                                    <h3>Exclusions</h3>
                                    <ul>
                                      {
                                        this.props.activeTour.exclusion !== undefined && this.props.activeTour.exclusion !== null
                                          ? this.props.activeTour.exclusion.map((data) => {
                                            return <li>
                                              {data}
                                            </li>
                                          })
                                          : null
                                      }
                                    </ul>
                                  </div>
                                </div>

                                <div id="conditions" className="section scrollspy sec-conditions">
                                  <div className="t-list">
                                    <h3>Conditions</h3>
                                    <ul>
                                      {
                                        this.props.activeTour.conditions !== undefined && this.props.activeTour.conditions !== null
                                          ? this.props.activeTour.conditions.map((data) => {
                                            return <li>
                                              {data}
                                            </li>
                                          })
                                          : null
                                      }
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tourtabwrap-mobi">
                    <ul className="collapsible">
                      <li>
                        <div className="collapsible-header">Overview</div>
                        <div className="collapsible-body sec-overview">
                          <div className="t-tabbox">

                            <p>{this.props.activeTour.overview}</p>

                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="collapsible-header">Itinerary</div>
                        <div className="collapsible-body sec-itinerary">
                          <div className="t-list">

                            {
                              this.props.activeTour.itinery.map((data, index) => {
                                return <ItineraryBlock data={data} itemItem={index}/>;
                              })
                            }

                          </div>

                        </div>
                      </li>
                      <li>
                        <div className="collapsible-header">Includes</div>
                        <div className="collapsible-body sec-inclusions">
                          <div className="t-list">

                            <ul>
                              {
                                this.props.activeTour.inclusion !== undefined && this.props.activeTour.inclusion !== null
                                  ? this.props.activeTour.inclusion.map((data) => {
                                    return <li>
                                      {data}
                                    </li>
                                  })
                                  : null
                              }
                            </ul>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="collapsible-header">Exclusions</div>
                        <div className="collapsible-body sec-exclusions">
                          <div className="t-list">
                            <ul>
                              {
                                this.props.activeTour.exclusion !== undefined && this.props.activeTour.exclusion !== null
                                  ? this.props.activeTour.exclusion.map((data) => {
                                    return <li>
                                      {data}
                                    </li>
                                  })
                                  : null
                              }
                            </ul>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div className="collapsible-header">Conditions</div>
                        <div className="collapsible-body sec-conditions">
                          <div className="t-list">
                            <ul>
                              {
                                this.props.activeTour.conditions !== undefined && this.props.activeTour.conditions !== null
                                  ? this.props.activeTour.conditions.map((data) => {
                                    return <li>
                                      {data}
                                    </li>
                                  })
                                  : null
                              }
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>

                  </div>

                </div>
                <div className="col l4 m12 s12">
                  <div className="tour-sidebox-wrap">
                    <div className="tour-sidebox">
                      <h3>Key Details</h3>
                      <div className="ts-items">
                        <div className="tour-side-row">
                          <div className="s-tour-title" style={{
                              textAlign: "Left"
                            }}>
                            <i className="sprite sprite-tour-location-icon"></i>Location</div>
                          <div className="s-tour-detail">
                            {
                              this.props.activeTour.location !== null
                                ? this.props.activeTour.location.name
                                : null
                            }
                          </div>
                        </div>

                        <div className="tour-side-row">
                          <div className="s-tour-title">
                            <i className="sprite sprite-clock"></i>Duration</div>
                          <div className="s-tour-detail">
                            {this.props.activeTour.duration_value}
                            {this.getDuration(this.props.activeTour.duration)}
                          </div>
                        </div>
                        <div className="tour-side-row">
                          <div className="s-tour-title">
                            <i className="sprite sprite-tour-location-icon"></i>Pickup Locations</div>
                          <div className="s-tour-detail">

                            {
                              this.props.activeTour.available_locations.length > 0
                                ? this.props.activeTour.available_locations[0].location_name
                                : null
                            }
                            {
                              this.props.activeTour.available_locations.length > 1
                                ? (<em>+{this.props.activeTour.available_locations.length - 1}
                                  more</em>)
                                : null
                            }
                          </div>
                        </div>
                        <div className="tour-side-row">
                          <div className="s-tour-title">
                            <i className="sprite tourtype"></i>Tour Type</div>
                          <div className="s-tour-detail">
                            {this.getTripType(this.props.activeTour.tour_type)}
                          </div>
                        </div>
                        <div className="tour-side-row">
                          <div className="s-tour-title">
                            <i className="sprite tourconform"></i>Confirmation</div>
                          <div className="s-tour-detail">
                            {this.getConfirmation(this.props.activeTour.sale_type)}
                          </div>
                        </div>
                        <div className="tour-side-row">
                          <div className="s-tour-title">
                            <i className="sprite tourlanguage"></i>Guiding Language</div>
                          <div className="s-tour-detail">
                            {this.props.activeTour.languages}
                          </div>
                        </div>
                      </div>
                      

                      <div className="tour-address-box">
                        Best Price Guaranted<br/>
                        No Booking Fees<br/>
                        Secure Payments<br/>
                        24/7 Support

                      </div>
                    </div>
                    <div className="tour-sidebox related">
                      <h3>Related Experiences</h3>
                      <ul style={{
                          paddingLeft: 35
                        }}>

                        {
                          this.props.Activity !== undefined
                            ? this.props.Activity.map((y) => {
                              return <li>

                                {y.title}
                              </li>
                            })
                            : null
                        }
                      </ul>
                    </div>
                    <div className="tour-sidebox category">
                      <h3>Related Categories</h3>
                      <div className="tags">
                        {
                          this.props.activeTour.categories !== undefined
                            ? this.props.activeTour.categories.map((data) => {
                              if(!data.checked){
                                return null;
                              }
                              return <a href={'/SearchResult?category=' + data.id} className="ts-cat">{data.label}</a>
                            })
                            : null
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ><Footer/></div>

    </Fragment>);
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(DayTourDetails);
