export const loadCartState = () => {
    
    try{
        const serializedState = sessionStorage.getItem('cart');       
        if(serializedState === null ){
            console.log('session not found');
            return undefined;
        }
        var state = JSON.parse(serializedState);
        return state.Cart;
    }catch(error){
        console.log('session not found');
        return undefined; 
    }
}



export const cartSaveState = (state) =>{
    
    try{
        const serializedState = JSON.stringify(state);
        sessionStorage.setItem('cart', serializedState);   
    }catch(error){
        console.log('session could not be saved!');
    }
    
}