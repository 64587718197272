import React from 'react';
import CartEditWidget from './CartEditWidget'

 const CartEdit = function (props) {

    function callbackFunction(childData){
        var data = childData
        props.callBack(data)
    }

    return <div className={"editinfo-wrap "+(props.open ? 'open':'')}>
        <div className="container">
            <CartEditWidget parentCallback={callbackFunction} item={props.data} itemIndex={props.itemIndex} tData={props.data.activity.activityObject} data={props.data.activity.activityObject.available_locations} />
              
        </div>   
    </div>;

}

CartEdit.defaultProps ={
    open:false
}

export default CartEdit;

