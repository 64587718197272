import { API_URL} from '../Actions/config';
import {startLoading,endLoading} from './CommonActions';
import store from '../Store';


export function fetchCategories(){

    return function (dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/api/category/get',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))
          .then((json) =>{
                if(json.httpCode === 200){
                    dispatch(storeCategories(json.results));
                }
                dispatch(endLoading());
          });
    }
}


export function storeCategories(cats){
    return{type:'STORE_CATEGORIES',categories:cats};
}
