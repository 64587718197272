export default function reducer(state={
    list: new Array(),
    result:[],
    duration:[
        {id:1, name:"Days" },
        {id:2, name:"Hours" },
        {id:3, name:"Minutes" },
        ],
    tour:[
        {id:0, name:"" },
        {id:1, name:"Day Tour" },
        {id:2, name:"Multi-day Tour" },

        ],
    code:[
        {id:0, name:"" },
        {id:1, name:"Instant" },
        {id:2, name:"On" },

        ],
    results:[],
    searchResults:[],
    recommendTours:[],
    activeTour: {
                    id:0,
                    title:"",
                    tour_type:0,
                    duration:0,
                    duration_value:0,
                    category: [],
                    overview:null,
                    adults_age_from:null,
                    adults_age_to:null,
                    children_age_from:null,
                    children_age_to:null,
                    infant_age_from:null,
                    infant_age_to:null,
                    itinery:[],
                    available_locations:[],
                    categories:[],
                    location:null,
                    cover_image:"",
                    thumb_image:"",
                    price:{selling_price:'',net_price:''},
                    languages:null,
                    seo_title:"",
                    seo_description:"",
                    seo_keywords:""
                },
    activeEstimationFormatted:"",
    pickupEstimates:[],
    activeEstimation:0,
    relatedTours:[],
    estimateUpdate:false,
    tourAvailable:false
},action){
switch(action.type){
    case "STORE_ACTIVITIES":{
        return{...state,list:action.list}
    }
    case "STORE_TOP_SELLING":{
        return{...state,result:action.list,}

    }
    case "STORE_SEARCH_ACTIVITIES":{
        return{...state,[action.store]:action.list}
    }
    case "STORE_ACTIVE_ACTIVITY":{
        return{...state,
                    activeTour:{
                                    id:action.activity.id,
                                    title:action.activity.title,
                                    tour_type:action.activity.tour_type,
                                    duration:action.activity.duration,
                                    duration_value:action.activity.duration_value,
                                    categories: action.activity.categories,
                                    overview:action.activity.overview,
                                    adults_age_from:action.activity.adults_age_from,
                                    adults_age_to:action.activity.adults_age_to,
                                    children_age_from:action.activity.children_age_from,
                                    children_age_to:action.activity.children_age_to,
                                    infant_age_from:action.activity.infant_age_from,
                                    infant_age_to:action.activity.infant_age_to,
                                    inclusion:action.activity.inclusion,
                                    exclusion:action.activity.exclusion,
                                    know:action.activity.know,
                                    conditions:action.activity.conditions,
                                    contact_number:action.activity.contact_number,
                                    custom_rating:action.activity.custom_rating,
                                    images: action.activity.images,
                                    itinery: action.activity.itinery,
                                    available_locations: action.activity.available_locations,
                                    know_policy:action.activity.know_policy,
                                    average_rating:action.activity.average_rating,
                                    location:action.activity.location,
                                    sale_type:action.activity.sale_type,
                                    pickup_location:action.activity.pickup_location,
                                    cover_image:action.activity.cover_image,
                                    thumb_image:action.activity.thumb_image,
                                    // selling_price : action.activity.price.selling_price,
                                    price:action.activity.price,
                                    languages:action.activity.languages,
                                    seo_title:action.activity.seo_title,
                                    seo_description:action.activity.seo_description,
                                    seo_keywords:action.activity.seo_keywords,
                                    min_date:action.activity.min_date,
                                    updated:true,
                                }
              }
    }
    case "LOCK_ACTIVE_ACTIVITY":{
        return{...state,
                    activeTour:{
                                    updated:false,
                                }
              }
    }
    case "LOADIND_END":{
        return{...state,loading:false} 
    }
    case "UPDATE_TOUR":{
        return {...state,activeTour:action.tour}
    }
    case "UPDATE_ESTIMATION":{ 
        return {...state,activeEstimationFormatted:action.pickuplocations, estimateUpdate:true}
    }
    
    case "STOP_UPDATE_ESTIMATION":{ 
        return {...state, estimateUpdate:false}
    }

    case "RELATED_TOURS":{
        return {...state,relatedTours:action.list}
    }

    case "STORE_AVAILABILITY":{
        return {...state,tourAvailable:action.availability}
    }
    default:
        break;
}
return state
}

