import {API_CLIENT_ID, API_CLIENT_SECRET, API_URL} from './config';
import {startLoading,endLoading, SnackBar,handleHTTPError } from './CommonActions';
import { CartRemoveAllItem } from './CartActions';
import store from '../Store';
 


export function registerAccount(data,UserType) {
    return function(dispatch) {
        dispatch(SnackBar(true,'Processing registration, please wait...','info'));
        fetch(API_URL+'/register',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },
            body:JSON.stringify({
                'client_id':API_CLIENT_ID,
                'client_secret':API_CLIENT_SECRET,
                'user_type':UserType,
                'first_name':data.first_name,
                'last_name':data.last_name,
                'email':data.email,
                'password':data.password,
                'confim_password':data.confim_password

            }),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());

                  if(UserType === 2){
                    dispatch(SignUpComplete('supplierSignUpComplete'));
                  }else{
                    dispatch(SignUpComplete('clientSignUpComplete'));
                  }
                  dispatch(SnackBar(true,'Registration complete','success'));
              });
        }).catch((error)=>{

          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not process registration','error'));
        });
    }

}

export function SignUpComplete(sfield) {
    return {
        type: 'SIGNUP_COMPLETE',
        field:sfield,

    }
}


export function getSearchSuggestions(keywords) {


    return function(dispatch) {

        if(keywords !== null && keywords !== undefined && keywords.length === 0){
            storeSearchSuggestions([]);
            return false;
        }

        fetch(API_URL+'/api/search/suggestions?key='+keywords+'&limit=5',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },

        }).then(handleHTTPError)
        .then((response)=>{
              response.json().then((json)=>{
                  dispatch(storeSearchSuggestions(json.results));
              });
        }).catch((error)=>{
          dispatch(SnackBar(true,'Server error','error'));
        });
    }

}


export function clearSearchSuggestions() {
    return {
        type: 'CLEAR_SEARCH_SUGGESTIONS',
        activities:[]
    }
}


export function storeSearchSuggestions(results) {
    return {
        type: 'STORE_SEARCH_SUGGESTIONS',
        activities:results
    }
}


export function MyBookings() {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/account/my-bookings',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },

        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(storeActivities(json.data.bookings));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }

}



export function storeActivities(list) {
    return {
        type: 'STORE_MY_BOOKINGS',
        list: list
    }
}


export function MyBooking(bookingId) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/account/my-bookings/show/'+bookingId,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },

        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(storeActiveBooking(json.data));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }

}



export function storeActiveBooking(data) {
    return {
        type: 'STORE_ACTIVE_BOOKING',
        booking: data
    }
}

export function AddMessage(bookingId,data) {

    return function(dispatch) {
        dispatch(startLoading());
        dispatch(SnackBar(true,'Posting message','info'));
        fetch(API_URL+'/api/account/my-bookings/'+bookingId+'/comments',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(MyBooking(bookingId));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }

}

export function GetMessages(bookingId) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/account/my-bookings/'+bookingId+'/comments',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },

        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(storeActiveBookingMessages(json.data));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }

}



export function storeActiveBookingMessages(messages) {
    return {
        type: 'STORE_ACTIVE_BOOKING_MESSAGES',
        messages: messages
    }
}


export function GetReviews(bookingId) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/account/reviews',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },

        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(storeReviews(json.data));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }

}



export function storeReviews(data) {
    return {
        type: 'STORE_REVIEWS',
        reviews: data.reviews
    }
}


export function ValidateBookingForm(data,agreement){
    return function(dispatch) {
        for(var x=0; x < data.length; x++){
            var y=x+1
            // const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

            // if(data[x].booking_object.traveller.length===0)
            // {
            //     dispatch(SnackBar(true,'Tour #'+y+` `+'Lead traveler details can not be empty','error'));
            //     return false;
            // }

            // if(data[x].booking_object.traveller[0].firstname=== undefined){
            //     dispatch(SnackBar(true,'Tour #'+y+` `+'Lead traveler first name can not be empty','error'));
            //     return false;
            // }

            // if(data[x].booking_object.traveller[0].lastname=== undefined){
            //     dispatch(SnackBar(true,'Tour #'+y+` `+'Lead traveler last name can not be empty','error'));
            //     return false;
            // }

            // if(data[x].booking_object.traveller[0].phone=== undefined){
            //     dispatch(SnackBar(true,'Tour #'+y+` `+'Lead traveler phone can not be empty','error'));
            //     return false;
            // }

            // if(data[x].booking_object.traveller[0].email=== undefined){
            //     dispatch(SnackBar(true,'Tour #'+y+` `+'Lead traveler email can not be empty','error'));
            //     return false;
            // }

            // if(data[x].booking_object.traveller[0].firstname.length === 0&&data[x].booking_object.traveller[0].lastname.length === 0 && data[x].booking_object.traveller[0].phone.length === 0 && data[x].booking_object.traveller[0].email.length === 0){
            //     dispatch(SnackBar(true,'Tour #'+y+` `+'Lead traveler details can not be empty','error'));
            //     return false;
            // }

            // if(data[x].booking_object.traveller[0].firstname.length === 0){
            //     dispatch(SnackBar(true,'Tour #'+y+` `+'Lead traveler first name can not be empty','error'));
            //     return false;
            // }

            // if(data[x].booking_object.traveller[0].lastname.length === 0){
            //     dispatch(SnackBar(true,'Tour #'+y+` `+'Lead traveler last name can not be empty','error'));
            //     return false;
            // }

            // if(data[x].booking_object.traveller[0].phone.length === 0){
            //     dispatch(SnackBar(true,'Tour #'+y+` `+'Lead traveler phone can not be empty','error'));
            //     return false;
            // }

            // if (isNaN(data[x].booking_object.traveller[0].phone)) {
            //     dispatch(SnackBar(true,'Tour #'+y+` `+'phone number not valid','error'));
            //     return false;
            // }

            // if(data[x].booking_object.traveller[0].email.length === 0){
            //     dispatch(SnackBar(true,'Tour #'+y+` `+'Lead traveler email can not be empty','error'));
            //     return false;
            // }
            // if(reg.test(data[x].booking_object.traveller[0].email) !== true){
            //     dispatch(SnackBar(true,'Tour #'+y+` `+'email not valid','error'));
            //     return false;
            // }






            if(data[x].booking_object.pickup_location === 'hotel'){

                if(data[x].booking_object.hotel_name.length === 0){
                    dispatch(SnackBar(true,'Tour #'+y+` `+'Pickup Location name can not be empty','error'));
                    return false;
                }

                if(data[x].booking_object.hotel_address.length === 0){
                    dispatch(SnackBar(true,'Tour #'+y+` `+'Pickup Location address can not be empty','error'));
                    return false;
                }

            }

            // if(data[x].booking_object.pickup_location === 'airport'){

            //     if(data[x].booking_object.flight_no.length === 0){
            //         dispatch(SnackBar(true,'Tour #'+y+` `+'Pick-up flight number can not be empty','error'));
            //         return false;
            //     }

            //     if(data[x].booking_object.flight_time.length === 0){
            //         dispatch(SnackBar(true,'Tour #'+y+` `+'Pick-up flight time can not be empty','error'));
            //         return false;
            //     }

            // }



        }

        // for(var x=0; x < data.length; x++){
        //     const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        //     if(data[data.length-1].booking_object.contact_firstname.length === 0 && data[data.length-1].booking_object.contact_lastname.length === 0 && data[data.length-1].booking_object.contact_email.length === 0&&data[data.length-1].booking_object.contact_phone.length === 0){
        //         dispatch(SnackBar(true,'Contact details can not be empty','error'));
        //         return false;
        //     }

        //     if(data[data.length-1].booking_object.contact_firstname.length === 0){
        //         dispatch(SnackBar(true,'Contact first name can not be empty','error'));
        //         return false;
        //     }

        //     if(data[data.length-1].booking_object.contact_lastname.length === 0){
        //         dispatch(SnackBar(true,'Contact last name can not be empty','error'));
        //         return false;
        //     }

        //     if(data[data.length-1].booking_object.contact_phone.length === 0){
        //         dispatch(SnackBar(true,'Contact phone can not be empty','error'));
        //         return false;
        //     }


        //     if (isNaN(data[data.length-1].booking_object.contact_phone)) {
        //         dispatch(SnackBar(true,'Tour #'+y+` `+'phone number not valid','error'));
        //         return false;
        //     }

        //     if(data[data.length-1].booking_object.contact_email.length === 0){
        //         dispatch(SnackBar(true,'Contact email can not be empty','error'));
        //         return false;
        //     }

        //     if(reg.test(data[data.length-1].booking_object.contact_email) !== true){
        //         dispatch(SnackBar(true,'Tour #'+y+` `+'email not valid','error'));
        //         return false;
        //     }



        // }

        // if(agreement === false){
        //     dispatch(SnackBar(true,'You need to agree to terms & conditions','error'));
        //     return false;
        // }


        dispatch(MakeBooking(data));
    }




}

export function BookingConfirmed(){
    return{type:'BOKING_CONFIRMED'};
}

export function MakeBooking(data) {

    return function(dispatch) {
        
        
        dispatch(startLoading());
        dispatch(SnackBar(true,'Placing your booking','info'));
        fetch(API_URL+'/api/booking/add-reservation',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8'
            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  console.log(json)
                    dispatch(endLoading());
                    dispatch(SnackBar(true,'Booking successful','success')); 
                    dispatch(CartRemoveAllItem()); 
                    dispatch(BackToHome()); 
                    
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not process the booking','error'));
        });
    }

}


export function BackToHome(){
    return{type:'BACK_TO_HOME'};
}

export function FetchSliders(){

    return function (dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/api/get-sliders',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))
          .then((json) =>{
                  dispatch(StoreSliders(json.results));
                  dispatch(endLoading());
          });
    }
}


export function StoreSliders(list){
    return{type:'STORE_SLIDERS',slides:list};
}


export function FetchFeaturedCat(){

    return function (dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/api/featured/categories/get',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))
          .then((json) =>{
                  dispatch(StoreFeaturedCat(json));
                  dispatch(endLoading());
          });
    }
}


export function StoreFeaturedCat(list){
    return{type:'STORE_FEATURED_CATS',cats:list};
}


export function GetSiteSettings(){

    return function (dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/api/settings/site/get',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))
          .then((json) =>{
                  dispatch(StoreSiteSettings(json));
                  dispatch(endLoading());
          });
    }
}


export function StoreSiteSettings(config){
    return{type:'STORE_SITE_SETTINGS',settings:config};
}

export function GetSiteContent(){

    return function (dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/api/content/site/get',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))
          .then((json) =>{
                  dispatch(StoreSiteContent(json));
                  dispatch(endLoading());
          });
    }
}


export function StoreSiteContent(config){
    return{type:'STORE_SITE_CONTENT',settings:config};
}


export function stateNull(){
    return{type:'STATE_NULL'}
}

export function stopStateEmpty(){
    return{type:'STOP_STATE_NULL'}
}




export function AddTailorMade(data) {

    return function(dispatch) {
        dispatch(startLoading());
        dispatch(SnackBar(true,'Posting Email','info'));
        fetch(API_URL+'/api/add-tailor-made',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',

            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(stateNull())
                  dispatch(SnackBar(true,'Your request has been sent','success'));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not save data','error'));
        });
    }

}

export function FetchDealAndDiscount(date){
  return function (dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/api/get-deals?from_date='+date,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              // 'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            }
        }).then(handleHTTPError)
          .then((response) =>{
            response.json().then((json)=>{
                dispatch(storeDealAndDiscount(json.results));
                dispatch(endLoading());
                });

            });

    }
}

export function storeDealAndDiscount(data){
    return{type:'STORE_DEAL_AND_DISCOUNT',dealAndDiscount:data};
}

export function storeSelectedDate(dte){
    return{type:'UPDATE_SELECTED_DATE',date:dte};
}