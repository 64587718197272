export default function reducer(state={
    list: new Array(),
    activeKey:{
        searchKey:"",
        updated:false
    }
    
},action){
    console.log(action.Key)
    switch(action.type){
        case "STORE_SEARCH_ACTIVITIES":{
            return{...state,searchResults:action.list}
        }        
    }
    switch(action.type){
        case "STORE_SEARCH_KEY":{
            return{...state,activeKey:{
                            searchKey:action.Key,
                            updated:true
            }}
        }        
    }
    switch(action.type){
        case "STORE_SEARCH_KEY_LOCK":{
            return{...state,activeKey:{
                            updated:false
            }}
        }        
    }

    return state;
}