import React, {Component, Fragment } from 'react';
import { findDOMNode } from 'react-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { fetchLocations } from '../../Actions/LocationAction'
import {storeSelectedDate, stateNull,FetchDealAndDiscount} from "../../Actions/WebsiteActions"
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
 
const $ = window.jQuery;

const pricing = [ 
                  { min: 0, max:50},
                  { min: 50, max:100},
                  { min: 100, max:150},
                  { min: 150, max:200}
                ];

const duration = [
                    { min: 0, max:1, label:"1 Day" },
                    { min: 1, max:2, label:"1 Day - 2 Days"},
                    { min: 2, max:4, label:"2 Days - 4 Days"},
                    { min: 4, max:8, label:"4 Days - 8 Days"}
                ];

const rating = [
                                    { value:1, label:"1 Star" },
                                    { value:2, label:"2 Stars" },
                                    { value:3, label:"3 Stars" },
                                    { value:4, label:"4 Stars" },
                                    { value:5, label:"5 Stars" },
                                ];

const mapStateToProps = (state) => {
  
  return {
    Locations: state.Locations.locations,
    discount:state.Website.dealAndDiscount
  }
}

const mapDispatchToProps = dispatch => ({

  fetchLocations: () => dispatch(fetchLocations()),
  storeSelectedDate:(date)=>dispatch(storeSelectedDate(date)),
  FetchDealAndDiscount: (date)=> dispatch(FetchDealAndDiscount(date)),

});







class SearchPanal extends Component {

  owlPanelRef = React.createRef();
  featuredItemsRef = React.createRef();

  calanderRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      location: "",
      duration: null,
      price: null,
      windowTop:0,
      pickupDate:this.props.date1,
      deal:parseInt(this.props.deal),
      rating:null,
      locationName:""
    }
    this.props.fetchLocations()
    this.props.FetchDealAndDiscount(this.props.date1)
    
  }


  componentDidMount = () => {

    const el = findDOMNode(this.refs.tsb);

    $(window).scroll(()=>{

      this.setState({
        windowTop:$(window).scrollTop()
      })

    });


    if(this.datepicker !== undefined && this.datepicker !== null){
      $(this.datepicker).datepicker({

        
      onSelect:(date)=>this.setState({pickupDate:new Date(date)},()=>{
        if(this.state.pickupDate>new Date())
          {
            this.props.onFilterChange(this.state.pickupDate,'pickupDate');
            this.props.storeSelectedDate(this.state.pickupDate);
            this.props.FetchDealAndDiscount(this.state.pickupDate);
            return false  
          }

          this.setState({
            pickupDate:new Date()
          },()=>{
            this.props.onFilterChange(this.state.pickupDate,'pickupDate');
            this.props.storeSelectedDate(this.state.pickupDate);
            this.props.FetchDealAndDiscount(this.state.pickupDate);

          })

      }),

       beforeShow: function(input, inst) {
           $('#ui-datepicker-div').removeClass(function() {
               return $('input').get(0).id;
           });
           $('#ui-datepicker-div').addClass(this.id);
       }
    });
    }

  }



  handleLocation = (locaton) => {
    this.setState({
      location: locaton,
      locationName:locaton.name
    },()=>{
      this.props.onFilterChange(this.state.location,'pickupLocation');
    });
  }

  handleDuration = (duration) => {
    this.setState({
      duration: duration
    },
    ()=>{
      this.props.onFilterChange(this.state.duration,'durationRange');
    })

  }

  handleRating = (rating) => {
    this.setState({
      rating: rating
    },()=>{
      this.props.onFilterChange(this.state.rating,'rating');
    })

  }

  handlePrice = (value) => {
    this.setState({
      price: value
    },
    ()=>{
      this.props.onFilterChange(this.state.price,'priceRange');
    }
  )

  }

  handleDeal = (event) => {
    if(parseInt(event.target.value)===parseInt(this.state.deal)){
      this.setState({
        deal: ""
      },
      ()=>{
        this.props.onFilterChange(this.state.deal,'deal');
      }
    )
    }
    else if(event.target.value!==undefined){
      this.setState({
        deal: event.target.value
      },
      ()=>{
        this.props.onFilterChange(this.state.deal,'deal');
      }
    )}

  }

  handleClearAll = () => {
    this.setState({
      location: "",
      locationName:"",
      duration: null,
      price: null,
      pickupDate: "",
      rating:null,
      deal:""
    },()=>{
        this.props.onFilterChange(this.state.pickupDate,'pickupDate');
       // this.props.onFilterChange(this.state.location,'pickupLocation');
        this.props.onFilterChange(this.state.price,'priceRange');
        this.props.onFilterChange(this.state.duration,'durationRange');
        this.props.onFilterChange(this.state.rating,'rating');
        this.props.onFilterChange(this.state.deal,'deal')
    })

  }



handlePickupDateBackspace = (e) =>{
  if (e.key === 'Backspace') {
        this.setState({
          pickupDate: "",
        },()=>{
            this.props.onFilterChange(this.state.pickupDate,'pickupDate');
        })

      }
}
handlePickFromBackspace = (e) =>{
  
  if (e.key === 'Backspace') {
        this.setState({
          location: null,
        },()=>{
          this.props.onFilterChange(this.state.location,'pickupLocation');
        })

      }
}
handlePriceBackspace = (e) =>{
  
  if (e.key === 'Backspace') {
        this.setState({
          price: null,
        },()=>{
          this.props.onFilterChange(this.state.price,'priceRange');
        })

      }
}
handleDurationBackspace = (e) =>{
  if (e.key === 'Backspace') {
        this.setState({
          duration: null,
        },()=>{
          this.props.onFilterChange(this.state.duration,'durationRange');
        })

      }
}
handleRatingBackspace = (e) =>{
  if (e.key === 'Backspace') {
        this.setState({
          rating:null,
        },()=>{
          this.props.onFilterChange(this.state.rating,'rating');
        })

      }
}

  formatDate = (date) => {
  var monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];

  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();

  return day + ' ' + monthNames[monthIndex] + ' ' + year;
}


  render() {
    return <Fragment>
      <div className="daytour-details">
        <div className={"toursearch-box "+( this.state.windowTop > 250 ? "fix":'') } ref={this.owlPanelRef}>
          <div className="t-date" ref="cal" >
            <div  onKeyDown={(e)=>{this.handlePickupDateBackspace(e) }} className="input-field col s12" style={{ marginLeft: -8 }}>
              <input className="tp-date-cal" ref="cal5" name="date" autoComplete="off" placeholder="Pickup Date" value={this.state.pickupDate !== "" && this.state.pickupDate !== null  ? this.formatDate(this.state.pickupDate):""} id="t-date-cal" ref={(r)=>{this.datepicker = r }} />
            </div>
          </div>
          <div className="t-pick-from">
            <div className="input-field col s12"   >
              <input type="text" className="tp-from" onChange={(e)=>{this.setState({locationName:e.target.value})}}  name="startingpoint" ref="pick1" placeholder="Pick From" value={this.state.locationName} />
            </div>
            <div className="dropbx pickfrom-box">

              <ul onChange={(e) => this.handleLocation(e, 'location')}      >
                {this.props.Locations.map((o) => {            
                    if(this.state.locationName !== ""){
                      
                      let regMatch = new RegExp(this.state.locationName,'gi')
                      if(o.name.match(regMatch)===null){
                        return null;
                      }
                    }
                  return <li><a onClick={()=>this.handleLocation(o)} style={{cursor:'pointer'}}>{o.name}</a></li>;
                })}
              </ul>
            </div>
          </div>
          <div className="t-price-deals">
            <div className="input-field col s12"  onKeyDown={(e)=>{this.handlePriceBackspace(e) }} >
              <input type="text" className="tp-price" ref="price1" placeholder="Price & Deals" name="priceMax" value={ this.state.price !== null ? '$'+this.state.price.min+" - "+"$"+this.state.price.max:""} />
            </div>
            <div className="dropbx price-box" >
              <ul>
                <li>Price</li>
                {pricing.map((o) => {
                  return <li><a onClick={()=>this.handlePrice(o)} style={{cursor:'pointer'}}>${o.min} - ${o.max}</a></li>;
                })}
              </ul>
              {this.props.discount !== null && this.props.discount.length > 0 ? 
              <ul>
                <li>Deals</li>
                {this.props.discount.map((o) => {
                  return <li>
                  <FormControlLabel
                    value={o.id}
                    onClick={(e)=>this.handleDeal(e)}
                    control={<Checkbox color="primary" />}
                    checked={parseInt(o.id)===parseInt(this.state.deal)}
                    label={o.title.substr(0, 15)}
                    labelPlacement={o.title}
                  /></li>;
                })}
                {/* <li><a  style={{cursor:'pointer'}}></a></li> */}
              </ul>
              :null}

            </div>

          </div>
          <div className="t-duration">
            <div className="input-field col s12"  onKeyDown={(e)=>{this.handleDurationBackspace(e) }} >
              <input type="text" className="tp-duration" autoComplete="off" name="duration" value={this.state.duration !== null ? this.state.duration.label:""} placeholder="Duration" />
            </div>
            <div className="dropbx duration-box" >
              <ul>
                {duration.map((o) => {
                  return <li><a onClick={()=>this.handleDuration(o)} style={{cursor:'pointer'}}>{o.label}</a></li>;
                })}
              </ul>
            </div>
          </div>
          <div className="t-rating">
            <div className="input-field col s12"  onKeyDown={(e)=>{this.handleRatingBackspace(e) }} >
              <input type="text" className="tp-rate" name="" placeholder="Rating" value={this.state.rating !== null ? this.state.rating.label:""} />
            </div>
            <div className="dropbx rating-box">
              <ul>
                {rating.map((o) => {
                  return <li><a onClick={()=>this.handleRating(o)} style={{cursor:'pointer'}}>{o.label}</a></li>;
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col s12 clr-wrap">
            <a onClick={()=>this.handleClearAll()} style={{cursor:'pointer'}} className="tb-clear">Clear All </a>
          </div>
        </div>
      </div>





    </Fragment>
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(SearchPanal);
