import React, {Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Headerinner from '../Pages/common/HeaderInner';
import Footer from '../Pages/common/Footer';

import { storeSearchKey } from '../Actions/SearchActions';
import { fetchCategories } from '../Actions/CategoryAction';
import { fetchLocations } from '../Actions/LocationAction';
import { TopSellingActivities, SearchActivities } from '../Actions/ActivityActions';


import FilterBar from '../Pages/common/Searchbar';
import SearchPanel from '../Pages/common/SearchPanel';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {Helmet} from "react-helmet";

import GridCard from './common/GridCard';
import ListCard from './common/ListCard';


const $ = window.jQuery;

const queryString = require('query-string'); 

const OwlCarouselOptions = {
  loop: false,
  dots: false,
  items: 3,
  margin: 20,
  nav: true,
  responsive: {
    0: {
      items: 1,
      nav: false,
      dots: true
    },
    600: {
      items: 2,
      dots: true
    },
    1000: {
      items: 2,
      dots: true
    },
    1200: {
      items: 3,
      dots: false
    }
  }
};

 
const mapStateToProps = (state) => {
  return {
    Categories: state.Categories.categories,
    Locations: state.Locations.locations,
    Featured: state.Activities.recommendTours,
    searchResults:state.Activities.searchResults,
  }
}

const mapDispatchToProps = dispatch => ({
  fetchCategories: () => dispatch(fetchCategories()),
  TopSellingActivities: () => dispatch(TopSellingActivities()),
  storeSearchKey: (Key) => dispatch(storeSearchKey(Key)),
  fetchLocations: () => dispatch(fetchLocations()),
  SearchActivities: (searchQuery,store) => dispatch(SearchActivities(searchQuery,store))  
});






class SearchResult extends Component {


  recSliderRef = React.createRef();

  owlRef = React.createRef();
  featuredItemsRef = React.createRef();

  constructor(props) {
    super(props);
    var { id } = this.props.match.params;
    var parsed = queryString.parse(this.props.location.search);
    

const query = new URLSearchParams(this.props.location.search);
const token = query.get('token')

    this.state = {

      view: parsed.view !== "" && parsed.view !== undefined ? parsed.view : 'grid',
      per_page: parsed.per_page !== "" && parsed.per_page !== undefined ? parsed.per_page : 1,
      limit: parsed.limit !== "" && parsed.limit !== undefined ? parsed.limit : 1,
      searhKeywords: parsed.key !== "" && parsed.key !== undefined ? parsed.key : null,
      location: parsed.location !== "" && parsed.location !== undefined ? parsed.location : "",
      durationMin: parsed.duration_min !== "" && parsed.duration_min !== undefined ? parsed.duration_min : 1,
      durationMax: parsed.duration_max !== "" && parsed.duration_max !== undefined ? parsed.duration_max : 14,
      category: parsed.category !== "" && parsed.category !== undefined ? parsed.category : "",
      priceMin: parsed.price_min !== "" && parsed.price_min !== undefined ? parsed.price_min : 10,
      priceMax: parsed.price_max !== "" && parsed.price_max !== undefined ? parsed.price_max : 10000,
      tour_type: parsed.tour_type !== "" && parsed.tour_type !== undefined ? parsed.tour_type : 0,
      featured: parsed.featured !== "" && parsed.featured !== undefined ? parsed.featured : 0,


      selecetedCategories:parsed.category !== "" && parsed.category !== undefined ? parsed.category.split(",") : "",
      selecetedLocations:parsed.location !== "" && parsed.location !== undefined ? parsed.location.split(",") : "",
      selecetedTourTypes:parsed.tourTypes !== "" && parsed.tourTypes !== undefined ? parsed.tourTypes.split(",") : "",
      pickupDate: parsed.pickupDate !== "" && parsed.pickupDate !== undefined ? new Date(parsed.pickupDate) : "",
      pickupLocation:parsed.pickupLocation !== "" && parsed.pickupLocation !== undefined ? parsed.pickupLocation : "",
      priceRange:null,
      durationRange: null,
      rating:null,
      Key:'',
      deal: parsed.deal !== "" && parsed.deal !== undefined ? parsed.deal : ""
    }

  }
  sliderRef;


  getUrlParameter = (name) => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    let results = regex.exec(window.location.search);

    return results === null ? '' :this.props.storeSearchKey({Key: decodeURIComponent(results[1].replace(/\+/g, ' '))});

  };


  handleChange = (event) => {
    this.setState({
      value: event.target.value
    })
  }

  handleCategory = (event) =>{

    if(event.target.value !== undefined && event.target.checked === true ){
      this.setState({
    ...this.state,
    selecetedCategories:[
      ...this.state.selecetedCategories,
      event.target.value
    ],
    view:'list'
  },()=>{
    this.searchActivities();
  });
    }

    if(event.target.value !== undefined && event.target.checked === false ){
      var array = [...this.state.selecetedCategories]; // make a separate copy of the array
      var index = array.indexOf(event.target.value)
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({selecetedCategories: array},()=>{
            this.searchActivities();
        });
      }
    }


}

clearCategories = () => {
  this.setState({
      selecetedCategories:[]
  },()=>this.searchActivities());
  
  ;
}


handleTourTypes = (event) =>{

  if(event.target.value !== undefined && event.target.checked === true ){
    this.setState({
  ...this.state,
  selecetedTourTypes:[
    ...this.state.selecetedTourTypes,
    event.target.value
  ],
  view:'list'
},()=>{

  this.searchActivities();
});
  }

  if(event.target.value !== undefined && event.target.checked === false ){
    var array = [...this.state.selecetedTourTypes]; // make a separate copy of the array
    var index = array.indexOf(event.target.value)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({selecetedTourTypes: array},()=>{
          this.searchActivities();
      });
    }
  }


}

clearTourTypes = () => {
  this.setState({
      selecetedTourTypes:[]
  },()=>this.searchActivities());
}


handleLocations = (event) =>{
  if(event.target.value !== undefined && event.target.checked === true ){
    this.setState({
  ...this.state,
  selecetedLocations:[
    ...this.state.selecetedLocations,
    event.target.value
  ],
  view:'list'
},()=>{
  this.searchActivities();
});
  }

  if(event.target.value !== undefined && event.target.checked === false ){
    var array = [...this.state.selecetedLocations]; // make a separate copy of the array
    var index = array.indexOf(event.target.value)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({selecetedLocations: array},()=>{
          this.searchActivities();
      });
    }
  }


}

clearLocations = () => {
  this.setState({
      selecetedLocations:[]
  },()=>this.searchActivities());
}


  handleSearchFilterUpdate = (field,value) =>{
      this.setState({
          [field]:value
      },()=>{
      });
  }


  formatDate = (date) => {
  var monthNames = [
    "01", "02", "03",
    "04", "05", "06", "07",
    "08", "09", "10",
    "11", "20"
  ];

  var day = date.getDate() < 10 ? '0'+date.getDate():date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();

  return year + '-' + monthNames[monthIndex] + '-' +day ;
}

  buildSearchQeury = () =>{

    let searchString = "";

    if(this.state.searhKeywords !== null && this.state.searhKeywords !== undefined){
        searchString = "?keyword="+this.state.searhKeywords;
    }

    if(this.state.selecetedCategories.length > 0){
      if(searchString === ""){
          searchString = searchString+"?category="+this.state.selecetedCategories.join(',');
      }else{
          searchString = searchString+"&category="+this.state.selecetedCategories.join(',');
      }
    }

    if(this.state.selecetedCategories.length > 0){
      if(searchString === ""){
          searchString = searchString+"?category="+this.state.selecetedCategories.join(',');
      }else{
          searchString = searchString+"&category="+this.state.selecetedCategories.join(',');
      }
    }

    if(this.state.selecetedTourTypes.length > 0){
      if(searchString === ""){
          searchString = searchString+"?tour_type="+this.state.selecetedTourTypes.join(',');
      }else{
          searchString = searchString+"&tour_type="+this.state.selecetedTourTypes.join(',');
      }
    }

    if(this.state.selecetedLocations.length > 0){
      if(searchString === ""){
          searchString = searchString+"?tour_location="+this.state.selecetedLocations.join(',');
      }else{
          searchString = searchString+"&tour_location="+this.state.selecetedLocations.join(',');
      }
    }

    if(this.state.pickupDate !== null && this.state.pickupDate !== "" ){
      if(searchString === ""){
          searchString = searchString+"?from_date="+this.formatDate(this.state.pickupDate);
      }else{
          searchString = searchString+"&from_date="+this.formatDate(this.state.pickupDate);
      }
    }

    if(this.state.pickupLocation !== null && this.state.pickupLocation !== "" ){
      if(searchString === ""){
          searchString = searchString+"?starting_point="+this.state.pickupLocation.id;
      }else{
          searchString = searchString+"&starting_point="+this.state.pickupLocation.id;
      }
    }

    if(this.state.priceRange !== null && this.state.priceRange !== "" ){
      if(searchString === ""){
          searchString = searchString+"?price_min="+this.state.priceRange.min+'&price_max='+this.state.priceRange.max;
      }else{
          searchString = searchString+"&price_min="+this.state.priceRange.min+'&price_max='+this.state.priceRange.max;
      }
    }

    if(this.state.priceRange !== null && this.state.priceRange !== "" ){
      if(searchString === ""){
          searchString = searchString+"?price_min="+this.state.priceRange.min+'&price_max='+this.state.priceRange.max;
      }else{
          searchString = searchString+"&price_min="+this.state.priceRange.min+'&price_max='+this.state.priceRange.max;
      }
    }

    if(this.state.durationRange !== null && this.state.durationRange !== "" ){
      if(searchString === ""){
          searchString = searchString+"?duration_min="+this.state.durationRange.min+'&duration_max='+this.state.durationRange.max;
      }else{
          searchString = searchString+"&duration_min="+this.state.durationRange.min+'&duration_max='+this.state.durationRange.max;
      }
    }

    if(this.state.rating !== null && this.state.rating !== "" ){
      if(searchString === ""){
          searchString = searchString+"?rating="+this.state.rating.value;
      }else{
          searchString = searchString+"&rating="+this.state.rating.value;
      }
    }

    if(this.state.deal !== null && this.state.deal !== "" ){
      if(searchString === ""){
          searchString = searchString+"?deal="+this.state.deal;
      }else{
          searchString = searchString+"&deal="+this.state.deal;
      }
    }

    return searchString;
  }

  searchActivities = () =>{
      let searchQuery = this.buildSearchQeury();

      this.props.SearchActivities(searchQuery !== "" ? searchQuery+'&featured=1&limit=5':searchQuery+'?featured=1&limit=5','recommendTours');
      this.props.SearchActivities(searchQuery,'searchResults');
  }

  componentDidMount = () =>{
      this.getUrlParameter('key')
      this.searchActivities();
  }

  handleFilterChange = (value,field) =>{

      this.setState({
          [field]:value
      },()=>{
          this.searchActivities();
      })
  }

  render() {
    return (<Fragment>
			
      <Helmet>
        <meta charSet="utf-8" />
        <title>Search Result</title>
        {/* <meta name="description" content="description" />
        <meta property="og:title" content="og:title" />
        <meta property="og:type" content="article" />
        <meta property="og:url" content="og:url" />
        <meta property="og:image" content="og:image" /> */}
      </Helmet>
      <Headerinner></Headerinner>
      <div className="contentwrap">
        <div className="title-wrap dt">
          <div className="container">
            <div className="row">
            <div className="breadscrumb-wrap">
               <div className="nav-wrapper">
                <div className="row">
                <div className="col l3 m12 s12">
                  <a  href="/" style={{backgroundColor:"white",paddingTop:25}} className="breadcrumb">Home</a>
                  <a  href="SearchResult" style={{backgroundColor:"white",paddingTop:25}} className="breadcrumb">Search Result</a>
                  </div>
                  <div className="col l9 m12 s12">
                    <div className="m-title"><h1>Day Tours & Activities</h1></div>
                  </div>
                </div>

                </div>
            </div>
            </div>
          </div>
        </div>
        <div className="day-tour-wrap">
          <div className="container">
            <div className="row">
              <div className="col l3 m12 s12">
                <a className="filtermobi">Filter Tours</a>
                <FilterBar handleCategory={this.handleCategory}
                           searchParams={this.state}
                           clearCategories={this.clearCategories}
                           handleTourType={this.handleTourTypes}
                           clearTourTypes={this.clearTourTypes}
                           handleLocations={this.handleLocations}
                           clearLocations={this.clearLocations}
                           filterUpdate={this.handleSearchFilterUpdate} location={this.props.location} ></FilterBar>
              </div>
              <div className="col l9 m12 s12">
                <SearchPanel
                     date1={this.state.pickupDate}
                     deal={this.state.deal}
                    searchParams={this.state}
                    onFilterChange={this.handleFilterChange}
                    handlePickupDate={this.handlePickupDate}  />
                {this.props.Featured !== undefined && this.props.Featured.length > 0 ? (
                  <div className="recommend-wrap">
                    <div className="row">
                      <div className="col m12 s12">
                        <h3>We Recommend</h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col m12 s12">
                        <div className="recommend-slider-wrap">

                          <OwlCarousel
                            className="owl-theme recommend-slider"

                            margin={10}
                            options={OwlCarouselOptions}
                            nav
                          > 
                            {this.props.Featured !== undefined && this.props.Featured.length > 0 ? this.props.Featured.map((data, index) => {
                              return <GridCard data={data} />;
                            }

                            ) : null}
                          </OwlCarousel>

                        </div>
                      </div>
                    </div>
                  </div>
                ):null }



                <div className="preferd-destination">
                  <div className="row">
                    <div className="col md12 s12">
                      <h2>Your Preference</h2>
                    </div>
                  </div>

                  {this.props.searchResults !== undefined && this.props.searchResults.length > 0 ? this.props.searchResults.map((data, index) =>
                      <ListCard data={data}/>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <Footer></Footer>

    </Fragment>
    );
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(SearchResult);
